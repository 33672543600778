export const state = () => ({
  list: [],
  loading: false,
  navigation: {
    perPage: 20,
    currentPage: 1,
    pagesCount: null
  }
});

export const mutations = {
  cleanProductList(state) {
    state.list = [];
  },

  updateProductsList(state, payload) {
    state.navigation.perPage = payload.response.limit;
    state.navigation.currentPage = payload.response.page;
    state.navigation.pagesCount = payload.response.pages;
    state.list = payload.response._embedded.items;
  }
};
