import Vue from 'vue'

Vue.mixin({
    methods: {
        updateArticleDataByCodeFromApi(code) {
            return this.$axios.$get(`/shop-api/page/by-code/${code}`);
        },

        updateArticleData(code) {
            return new Promise((resolve, reject) => {
                const getContent = () => {
                    let result = this.$store.state.articles.workingList
                        .find(art => art.slug === code || art.code === code);

                    if (result) {
                        this.$store.commit('article/updateArticleData', result);
                        resolve();

                    } else {
                        this.updateArticleDataByCodeFromApi(code)
                            .then(response => {
                                this.$store.commit('article/updateArticleData', response);
                                resolve();
                            })
                            .catch(err => {
                                this.devLog('updateArticleDataByCodeFromApi err', err);
                                reject();
                            });
                    }
                }

                if (this.$store.state.articles.workingList.length) {
                    getContent();

                } else {
                    this.updateArticlesWorkingList().then(() => getContent())
                }
            });
        },
    }
});
