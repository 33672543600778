export const state = () => ({
    products: {
        productPhrase: null,
        productResults: [],
        page: 1,
        limit: 10,
        pages: 1,
        productsListRequest: null
    },
    sortMethod: {
        name: 'Domyślnie',
        method: '&sorting[default]'
    }
});

export const mutations = {
    setSortMethod(state, sortMethod) {
        state.sortMethod = sortMethod
    },
    setProductsListRequest(state, request) {
        state.products.productsListRequest = request;
    },

    setNullOnProductListRequest(state) {
        state.products.productsListRequest = null;
    },

    cancelProductsListRequest(state) {
        state.products.productsListRequest.cancel();
    },

    cleanProductResults(state) {
        state.products.productResults = [];
    },

    updateProductResults(state, payload) {
        !!payload.productPhrase ? state.products.productPhrase = payload.productPhrase : null;
        state.products.productResults = payload.response.items;
        state.products.page = payload.response.page;
        state.products.limit = payload.response.limit;
        state.products.pages = payload.response.pages;
    },

    setDefaultPagination(state) {
        state.products.page = 1;
        state.products.limit = 10;
        state.products.pages = 1;
    },

    setProductPhrase(state, payload) {
        state.products.productPhrase = payload;
        state.products.page = 1;
    }
};
