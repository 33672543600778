export const state = () => ({
  token: null
});

export const mutations = {
  setBasketToken(state, payload){
    state.token = payload.tokenValue;
  },

  cleanBasketTokenAfterLogout(state) {
    state.token = null;
  }
};
