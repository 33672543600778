<template>
  <div class="clean-layout">
    <loading/>
    <nuxt/>
    <layout-logo-imgs />
  </div>
</template>

<script type="text/javascript">
  import Loading from '~/components/UtilityComponents/Loading';
  import LayoutLogoImgs from '~/components/LayoutComponents/LayoutLogoImgs';

  export default {
    name: 'clean',
    components: {
      Loading,
      LayoutLogoImgs,
    }
  }
</script>

<style lang="scss">
  .clean-layout{}
</style>
