export const state = () => ({
    data: null,
    loaded: false
});

export const mutations = {
    cleanArticleData(state) {
        state.loaded = false;
        state.data = null;
    },

    updateArticleData(state, payload) {
        state.loaded = true;
        state.data = payload;
    },
};
