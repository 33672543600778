<template>
  <div :class="{preventScroll: isMobileModalOpen, 'wcag-contrast': contrastMode}">
    <loading/>
    <notifications/>
    <layout-header/>
    <b-container>
      <nuxt/>
    </b-container>
    <layout-footer/>
    <layout-logo-imgs />
  </div>
</template>

<script type="text/javascript">
  import LayoutHeader from '~/components/LayoutComponents/LayoutHeader';
  import LayoutFooter from '~/components/LayoutComponents/LayoutFooter';
  import Loading from '~/components/UtilityComponents/Loading';
  import LayoutLogoImgs from '~/components/LayoutComponents/LayoutLogoImgs';

  export default {
    name: 'Default',
    components: {
      LayoutFooter,
      LayoutHeader,
      Loading,
      LayoutLogoImgs,
    }
  }
</script>

<style lang="scss"></style>
