export const state = () => ({
  product: {},
  delivery: {},
  productRowId: null,
  correctlyCompleted: true
});

export const mutations = {
  setCorrectlyCompleted(state,value){
    state.correctlyCompleted = value;
  },

  add(state, payload) {
    state.product = payload.product;
    state.delivery = payload.delivery;
    if (payload.productRowId !== undefined) {
      state.productRowId = payload.productRowId;
    }
  },

  setQuantity(state, quantity){
    state.product.quantity = quantity;
  },

  setRequestedAmount(state, quantity){
    state.product.requestedAmout = quantity;
  },

  cleanData(state) {
    state.product = {};
    state.delivery = {};
    state.productRowId = null;
  },

  toggleOption(state, optCode) {
    let option = state.product.options.find(option => {
      return option.code === optCode
    });

    if (option.active && !option.required) {
      option.active = false;
      !!option.suboptions ? option.suboptions.forEach(suboption => {
        suboption.active = false
      }) : '';
    } else {
      option.active = true;
    }
  },

  toggleOptionValue(state, payload) {
    let optCode = payload.optCode;
    let optValCode = payload.optValCode;

    let option = state.product.options.find(option => {
      return option.code === optCode
    });

    let optionValue = option.suboptions.find(element => {
      return element.code === optValCode
    });

    let attribute = optionValue.options[0] || null;

    if (optionValue.active) {
      optionValue.active = false;
      !!attribute ? attribute.active = false : '';
    } else {
      option.suboptions.forEach(element => {
        element.active = false;
        !!element.options[0] ? element.options[0].active = false : '';
      });
      optionValue.active = true;
      !!attribute ? attribute.active = true : '';
    }

    state.correctlyCompleted = true;
  },

  toggleAttributeValue(state, payload) {
    let optCode = payload.optCode;
    let optValCode = payload.optValCode;
    let attrCode = payload.attrCode;
    let attrValCode = payload.attrValCode;

    let option = state.product.options.find(option => {
      return option.code === optCode
    });

    let optionValue = option.suboptions.find(element => {
      return element.code === optValCode
    });

    let attribute = optionValue.options.find(element => {
      return element.code === attrCode
    });

    let attributeValue = attribute.options.find(element => {
      return element.code === attrValCode
    });

    let subattribute = attributeValue.options[0] || null;

    if (attributeValue.active) {
      attributeValue.active = false;
      !!subattribute ? subattribute.active = false : '';
    } else {

      attribute.active = true;

      attribute.options.forEach(element => {
        element.active = false;
        !!element.options[0] ? element.options[0].active = false : '';
      });

      attributeValue.active = true;
      !!subattribute ? subattribute.active = true : '';
    }

    state.correctlyCompleted = true;
  },

  toggleSubttributeValue(state, payload) {
    let optCode = payload.optCode;
    let optValCode = payload.optValCode;
    let attrCode = payload.attrCode;
    let attrValCode = payload.attrValCode;
    let subattrCode = payload.subattrCode;
    let subattrValCode = payload.subattrValCode;

    let option = state.product.options.find(option => {
      return option.code === optCode
    });

    let optionValue = option.suboptions.find(element => {
      return element.code === optValCode
    });

    let attribute = optionValue.options.find(element => {
      return element.code === attrCode
    });

    let attributeValue = attribute.options.find(element => {
      return element.code === attrValCode
    });

    let subattribute = attributeValue.options.find(element => {
      return element.code === subattrCode
    });

    let subattributeValue = subattribute.options.find(element => {
      return element.code === subattrValCode
    });

    if (subattributeValue.active) {
      subattributeValue.active = false;
    } else {
      subattribute.options.forEach(element => {
        element.active = false;
      });
      subattributeValue.active = true;
    }

    state.correctlyCompleted = true;
  }
};
