export const state = () => ({
  list: []
});

export const mutations = {
  add (state, product) {
    state.list.length <= 2 ? state.list.push(product) : '';
  },
  remove (state, product) {
    state.list.splice(state.list.findIndex(i => +i.code == +product.code), 1);
  }
};
