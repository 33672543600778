import Vue from 'vue'

Vue.mixin({
    methods: {
        updateDeliveryMethods(orderId) {
            this.$axios.$get(`/shop-api/order/${orderId}/shipping-methods`)
                .then(response => {
                    this.$store.commit('delivery/updateDeliveryMethods', response);
                    this.updateTotalsByDeliveryMethod();
                })
                .catch(err => this.devLog('updateDeliveryMethods', err));
        },

        getDataFromGUS(nip, successCallback, failCallback, context) {
            this.$axios.$get('/shop-api/me/gus/' + nip)
                .then(response => successCallback(response, context))
                .catch(err => {
                    this.devLog('getDataFromGUS', err);
                    failCallback();
                });
        },

        updateTotalsByDeliveryMethod() {
            let token = this.$store.state.basket_token.token;
            let shippingMethod = this.$store.state.delivery.deliveryMethods.find(m => m.active);
            let shippingMethodCode = shippingMethod && shippingMethod !== -1 ? shippingMethod.code : null;
            let countryCode = this.$store.state.delivery.deliveryAddress.countryCode;
            let postCode = this.$store.state.delivery.deliveryAddress.postcode;

            if (!countryCode || countryCode.length === 0) {
                countryCode = 'PL';
            }

            if (token && shippingMethodCode && countryCode && postCode) {
                this.$axios.$get(
                    '/shop-api/carts/' + token + '/estimated-shipping-cost-by-code',
                    {
                        params: {
                            shippingMethodCode,
                            countryCode,
                            postCode,
                        }
                    }
                )
                    .then(response => this.$store.commit('basket/updateShippingPrice', response))
                    .catch(err => this.devLog('updateTotalsByDeliveryMethod', err));
            }
        },

        isPostcodeExist(postCode, callback) {
            this.$store.commit('loading/show', this.$t('Weryfikacja danych...'));

            return this.$axios.$get('/shop-api/checkout/check-postcode/' + postCode)
                .then(response => callback(response))
                .catch(err => this.devLog('isPostcodeExist', err))
                .then(() => this.$store.commit('loading/hide'));
        }
    }
});
