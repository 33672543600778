<template>
  <div class="wrapper">
    <loading/>
    <notifications/>
    <nuxt/>
  </div>
</template>

<script type="text/javascript">
  import Loading from '~/components/UtilityComponents/Loading';

  export default {
    components: {
      Loading,
    }
  }
</script>

<style lang="scss" scoped>
  .wrapper {
    width: 100vw;
    height: 100vh;
  }
</style>
