import Vue from 'vue';
import debounce from 'lodash/debounce';

const devLog = (label, data, env) => {
    if (!!env.debug) {
        let css = 'font-size: 13px; background: green; color: white; display: block; padding: 3px 5px 3px 0';
        console.log('%c devLog (onNuxtReady)%s', css, label, '\n', data);
    }
}

let REFRESHING = false;
let REFRESHING_REQUEST = null;

export default function ({route, $axios, redirect, store, env}) {
    // Request interceptor for API calls
    $axios.interceptors.request.use(
        async config => {

            if (!!store.state.user.token && !config.url.includes('register')) {
                config.headers = {
                    'Authorization': `Bearer ${store.state.user.token}`
                }
            }
            config.headers['Content-Type'] = 'application/json;charset=utf-8';
            return config;
        },
        error => {
            Promise.reject(error)
        });

    // Response interceptor for API calls
    $axios.interceptors.response.use(
        response => response,
        error => {
            const status = !!error.response && !!error.response.status && error.response.status === 401;

            if (status && error.response.data.message === 'Expired JWT Token') {
                return refreshAccessToken(store, redirect).then(() => {
                    error.config.headers['Content-Type'] = 'application/json;charset=utf-8';
                    return $axios.request(error.config);
                });
            }

            if (status && error.response.data.message === 'Invalid JWT Token') {
                REFRESHING = false;
                REFRESHING_REQUEST = null;
                logout(store, redirect, 'Niepoprawny token sesji, zaloguj się ponownie');
            }

            return Promise.reject(error);
        }
    );

    const getJwtTokens = () => {
        let l_storage = localStorage.getItem('vuex');
        l_storage = JSON.parse(l_storage);
        let token = '';
        let refreshToken = '';

        if (store.state && store.state.user.token && store.state.user.refreshToken) {
            token = store.state.user.token;
            refreshToken = store.state.user.refreshToken;

            return {
                token,
                refreshToken
            };
        }

        if (l_storage && l_storage.user && l_storage.user.token) {
            token = l_storage.user.token;
            refreshToken = l_storage.user.refreshToken;

            return {
                token,
                refreshToken
            };
        }
    }

    const refreshAccessToken = (store, redirect) => {
        const refreshToken = getJwtTokens().refreshToken;

        if (REFRESHING) {
            return REFRESHING_REQUEST;
        }

        if (refreshToken) {
            REFRESHING = true;
            REFRESHING_REQUEST = $axios.$post('/shop-api/token/refresh?refresh_token=' + refreshToken)
                .then(response => {
                    store.commit('user/login', response);
                    REFRESHING = false;
                    REFRESHING_REQUEST = null;
                    return Promise.resolve(true);
                })
                .catch(error => {
                    REFRESHING = false;
                    REFRESHING_REQUEST = null;
                    logout(store, redirect);
                    return Promise.reject(error);
                });
        }
        return REFRESHING_REQUEST;
    }

    const logout = debounce(function (store, redirect, msg = 'Sesja wygasła. Zaloguj się ponownie') {
            store.commit('user/logout');
            redirect('/login');
            setTimeout(
                () => Vue.notify({title: msg}),
                500
            );
        }, 5000, {'leading': true}
    );

    window.onNuxtReady(() => {
        if (store.state.user.token) {
            $axios.$get('/shop-api/me')
                .then(resp => store.commit('user/updateClientData', resp))
                .catch(err => devLog('/shop-api/me', err, env));
        }

        $axios.$get('/shop-api/shop-info')
            .then(response => store.commit('basic_data/setData', response))
            .catch(err => devLog('/shop-api/shop-info', err, env));

        $axios.$get('/shop-api/taxons/')
            .then(response => store.commit('categories/updateCategoryList', {response: response}))
            .catch(err => devLog('/shop-api/taxons/', err, env));

        if (!store.state.basket_token.token) {
            if (!store.state.user.token) {
                $axios.$post('/shop-api/carts')
                    .then(response => {
                        store.commit('basket/getNewBasketAsQuest', response);
                        store.commit('basket_token/setBasketToken', response);
                    })
                    .catch(err => devLog('/shop-api/carts', err, env));

            } else {
                $axios.$post('/shop-api/customer/carts')
                    .then(response => {
                        store.commit('basket/updateBasketAfterLogged', response);
                        store.commit('basket_token/setBasketToken', response);

                        if (route.name !== 'basket' && !store.state.basket.basketRequest) {
                            const token = store.state.basket_token.token;
                            store.commit('basket/setBasketRequest', $axios.CancelToken.source());

                            $axios.$get(
                                '/shop-api/carts/' + token,
                                {cancelToken: store.state.basket.basketRequest.token}
                            )
                                .then(response => store.commit('basket/updateBasket', response))
                                .catch(err => {
                                    if (err.response.status === 404) {
                                        !!store.state.user.token ? window.$nuxt.updateBasketAfterLogged() : window.$nuxt.getNewBasketAsQuest();
                                    }
                                })
                                .then(() => store.commit('basket/cancelBasketRequest'))
                        }
                    })
                    .catch(err => devLog('/shop-api/customer/carts', err, env));
            }
        }
    });
}
