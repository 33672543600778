export const state = () => ({
  data: null
});

export const mutations = {
  cleanData(state){
    state.data = null;
  },
  updateData(state, response) {
    state.data = response;
  }
};

