export const state = () => ({
    list: [],
    currentArticlesCategoryCode: null
});

export const mutations = {
    selectArticleCategory(state, code) {
        const manageSelectedParameter = (items, code) => {
            items.forEach(item => {
                item.selected = (item.code === code) || (item.link === code);

                if (item.selected) {
                    state.currentArticlesCategoryCode = item.code;
                }

                if (!!item.children && item.children.length) {
                    manageSelectedParameter(item.children, code);
                }
            });
        }

        if (code === null) {
            state.currentArticlesCategoryCode = null;
        }

        manageSelectedParameter(state.list, code);
    },

    setNullOnCurrentArticlesCategoryCode(state) {
        state.currentProductsCategoryCode = null;
    },

    cleanArticlesCategoryList(state) {
        state.list = [];
    },

    updateArticleCategoriesList(state, payload) {
        state.list = [];

        payload.response.forEach(cat => {
            cat.selected = false;

            if (cat.children && cat.children.length) {
                cat.children.forEach(subcat => subcat.selected = false);
            }

            state.list.push(cat)
        });
    },
};

