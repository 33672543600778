export const state = () => ({
    deliveryMethods: [],
    deliveryMethodsJSON: null,
    deliveryAddress: {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        postcode: '',
        city: '',
        street: '',
        countryCode: ''
    },
    invoiceData: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        nip: '',
        company: '',
        postcode: '',
        city: '',
        street: '',
        countryCode: ''
    },
    deliveryCoordinates: [],
    areDeliveryCoordinatesSelectByUser: false,
    defaultCoordinatesByAddress: [],
    commentsToOrder: {
        comment: ''
    },
    montage: {
        startDate: '',
        endDate: '',
    },
    withCommentsToOrderOption: false,
    wasDeliveryDataSaved: false,
    deliveryDates: []
});

export const mutations = {
    selectMethod(state, payload) {
        Object.values(state[payload.methods_type]).forEach(option => {
            option.active = option.code === payload.method_code;
        });
    },

    updateDeliveryMethods(state, payload) {
        const newJSON = JSON.stringify(payload.shipments);

        if (!state.deliveryMethodsJSON || (state.deliveryMethodsJSON !== newJSON)) {
            state.deliveryMethodsJSON = newJSON;
            state.deliveryMethods = payload.shipments.map((shipment, i) => {
                shipment.active = i === 0;
                return shipment;
            });
        }
    },

    setDeliveryCoordinates(state, deliveryCoordinates) {
        state.deliveryCoordinates = deliveryCoordinates;
        state.areDeliveryCoordinatesSelectByUser = true;
    },

    setDefaultCoordinatesByAddress(state, defaultCoordinates) {
        state.defaultCoordinatesByAddress = defaultCoordinates;
    },

    saveData(state, payload) {
        payload.data.forEach(input_params => {
            state[payload.dataType][input_params.input] = input_params.value;
        });
    },

    toggleOption(state, payload) {
        state[payload.option_type] = !state[payload.option_type];
    },

    checkOption(state, payload) {
        state[payload.option_type] = true;
    },

    cleanData(state) {
        Object.keys(state.deliveryAddress).forEach(input_param => state.deliveryAddress[input_param] = '');
        Object.keys(state.invoiceData).forEach(input_param => state.invoiceData[input_param] = '');

        state.commentsToOrder.comment = '';
        state.withCommentsToOrderOption = false;
        state.wasDeliveryDataSaved = false;
        state.montage.startDate = '';
        state.montage.endDate = '';
        state.areDeliveryCoordinatesSelectByUser = false;


        // TODO SPRAWDZIĆ CO TU SIĘ DZIEJE
        Object.values(state.deliveryMethods).forEach(option => option.active = false);
        !!state.deliveryMethods[0] ? state.deliveryMethods[0].active = true : '';
    }
};
