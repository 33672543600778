export const state = () => ({
    specialOffersList: [],
    featuredProductsList: [],
});

export const mutations = {
    updateSpecialOffersList(state, payload) {
        state.specialOffersList = payload;
    },

    updateFeaturedProductsList(state, payload) {
        state.featuredProductsList = payload;
    },
};
