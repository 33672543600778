export const state = () => ({
    fontSizeMode: 0,
    contrastMode: false
});

export const mutations = {
    setFontSizeMode(state, payload) {
        state.fontSizeMode = payload.fontSizeMode;
    },

    setContrastMode(state, payload) {
        state.contrastMode = payload.contrastMode;
    }
};
