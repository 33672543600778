export const state = () => ({
  token: null,
  refreshToken: null,
  clientData: {
    billingAddress: {
      city: null,
      company: null,
      countryCode: null,
      default: null,
      firstName: null,
      id: null,
      lastName: null,
      phoneNumber: null,
      postcode: null,
      provinceName: null,
      street: null,
      nip: null
    },
    email: null,
    firstName: null,
    gender: null,
    id: null,
    lastName: null,
    phoneNumber: null,
    subscribedToNewsletter: null,
    nip: null
  },
  addresses: [],
  me: null,
  data: {
    email: null,
    fullName: null,
    addresses: [],
    invoiceData: null
  }
});

export const mutations = {
  login(state, payload) {
    state.token = payload.token;
    state.refreshToken = payload.refresh_token;
  },

  logout (state) {
    state.token = null;
    state.refreshToken = null;

    state.clientData = {
      billingAddress: {
        city: null,
        company: null,
        countryCode: null,
        default: null,
        firstName: null,
        id: null,
        lastName: null,
        phoneNumber: null,
        postcode: null,
        provinceName: null,
        street: null,
        nip: null
      },
      email: null,
      firstName: null,
      gender: null,
      id: null,
      lastName: null,
      phoneNumber: null,
      subscribedToNewsletter: null,
      nip: null
    };

    state.addresses = [];
  },

  updateClientData(state, payload) {
    state.clientData = payload;
  },

  updateNewsletterClientStatus(state, payload) {
    state.clientData.subscribedToNewsletter = payload;
  },

  updateAddresses(state, payload) {
    state.addresses = payload;
  },

  setMe (state, data) {
    state.me = data;
  },

  update (state, data) {
    state.data = Object.assign({}, state.data, data);
  },

  setMainAddress (state, address) {
    for (let i = 0; i < state.data.addresses.length; i++) {
      state.data.addresses[i].main = state.data.addresses[i] === address;
    }
  }
};

export const getters = {
  me: state => state.me || {}
};
