import createPersistedState from 'vuex-persistedstate'

export default ({store}) => {
  createPersistedState({
    paths: [
      'user',
      'basic_data',
      'categories',
      'configurator3d',
      'banners',
      'wcag',
      'basket_token'
    ],
  })(store)
}
