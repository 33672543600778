<template>
  <nuxt-link class="icon-link-btn" :to="to || '/'" :class="{selected, userPanel}">
    <span v-if="icon" class="icon fs-15">
      <fa :icon="icon"/>
    </span>
    <span class="label">
      <slot/>
    </span>
  </nuxt-link>
</template>

<script>
  export default {
    name: 'IconLinkBtn',
    props: ['to', 'icon', 'selected', 'userPanel']
  }
</script>

<style lang="scss" scoped>
  .icon-link-btn {
    user-select: none;

    .label{
      position: relative;
      padding-bottom: 2px;

      &:before {
        content: '';
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: $textColor19;
        opacity: 0;
        transition: opacity 0.3s;
      }
    }

    .icon {
      display: inline-block;
      margin-right: 1rem;
      width: 17px;
      text-align: center;
    }

    &.selected {
      color: #00ad52;

      .label:before {
        opacity: 1;
      }
    }

    &.userPanel {
      display: flex;

      .icon {
        flex-shrink: 0;
      }
    }
  }
</style>
