export const state = () => ({
    paymentMethods: [],
    paymentMethodsJSON: null,
});

export const mutations = {
    selectMethod(state, payload) {
        Object.values(state[payload.methods_type]).forEach((option) => {
            option.active = option.code === payload.method_code;
        });
    },

    updatePaymentMethods(state, payload) {
        const newJSON = JSON.stringify(payload[0].payments);

        if (!state.paymentMethodsJSON || (state.paymentMethodsJSON !== newJSON)) {

            state.paymentMethodsJSON = newJSON;
            state.paymentMethods = payload[0].payments.map((payment, i) => {
                payment.active = i === 0;
                return payment;
            });
        }
    },

    cleanData(state) {
        Object.values(state.paymentMethods).forEach((option) => {
            option.active = false
        });

        // TODO SPRAWDZIĆ CO TU SIĘ DZIEJE
        !!state.paymentMethods[0] ? state.paymentMethods[0].active = true : '';
    }
};
