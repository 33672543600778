import Vue from 'vue'
import fileSaver from 'file-saver';
import get from 'lodash/get';
import map from 'lodash/map';

Vue.mixin({
    computed: {
        jwtToken() {
            return this.$store.state.user.token;
        },

        jwtRefreshToken() {
            return this.$store.state.user.refreshToken;
        },

        isUserLogged() {
            return !!this.jwtToken;
        },

        basketToken(){
            return this.$store.state.basket_token.token;
        },

        activeProdsCat() {
            let list = this.$store.state.categories.list;
            let code = this._currentProductsCategoryCode;

            function searchByCode(elements, code) {
                let temp = null;

                elements.forEach(element => {
                    if (element.code === code) {
                        temp = element;

                    } else if (!!element.children && element.children.length && temp === null) {
                        temp = searchByCode(element.children, code);
                    }
                });

                return temp;
            }

            return searchByCode(list, code);
        },

        productsBasket() {
            return this.$store.state.basket.productRows || [];
        },

        isMobileModalOpen() {
            return this.$store.state.product_filters.isMobileFiltersOpen || this.$store.state.categories.openedMenu;
        },

        contrastMode() {
            return this.$store.state.wcag.contrastMode ?? false;
        },

        montageDaysSum() {
            let sum = 0;

            this.productsBasket.forEach(row => {
                let rowSum = get(row, 'product.variants[0].montageInformation.time', 0);
                let quantity = get(row, 'quantity', 0);
                let options = get(row, 'product.variants[0].options', []);
                let activeSuboption = null;

                options.forEach(option => {

                    if (option.active && !!option.suboptions) {
                        activeSuboption = option.suboptions.find(suboption => suboption.active);

                        if (!!activeSuboption && !!activeSuboption.montageTime) {
                            rowSum += activeSuboption.montageTime;
                        }
                    }
                });

                sum += rowSum * quantity;
            });

            return sum;
        }
    },

    methods: {
        getProductHref(product) {
            let temp = '/product/';

            if (!!product) {

                if (!!product.variantShopSlug && !!product.variantShopSlug.length) {
                    temp = product.variantShopSlug;
                } else if (!!product.code) {
                    temp += product.code
                }
            }

            return temp;
        },

        formatPrice(value) {
            let temp = null;
            let currency = this.$store.state.basic_data.currency;
            let fixed = 2;

            if (!!value || value === 0) {
                temp = value / 100;
                temp = temp.toFixed(fixed);

                let re = new RegExp('^-?\\d+(?:\.\\d{0,' + fixed + '})?');

                temp = temp.toString().match(re)[0];
                temp = parseFloat(temp);

                temp = temp.toLocaleString('pl-PL', {
                    useGrouping: 'true',
                    minimumFractionDigits: fixed,
                    maxFractionDigits: fixed,
                    style: 'currency',
                    currency: !!currency ? currency : 'PLN'
                });
            }

            return temp;
        },

        formatValue(value) {
            if (value === true) {
                return 'Tak';
            } else if (value === false) {
                return 'Nie';
            } else {
                return value;
            }
        },

        goTo(route) {
            this.closeMobileMenu();
            this.$router.push(route);
        },

        closeMobileMenu() {
            !!screen && !!screen.width && screen.width < 751 ? this.$store.commit('categories/closeMenu') : '';
        },

        formatCountries(data) {
            return map(data, countryCode => ({
                value: countryCode,
                text: this.$t(countryCode),
            }));
        },

        formatDate(timestamp) {
            return new Date(timestamp).toLocaleDateString('pl-PL');
        },

        getProductQuantityByUnitRatio(selectedQuantity, productUnitRatio) {
            return Math.ceil(parseFloat(selectedQuantity) / parseFloat(productUnitRatio));
        },

        isUndefinedOrNull(val) {
            return typeof val === 'undefined' || val === null;
        },

        saveDataToJson(data = {}, fileName = 'mock') {
            let json = JSON.stringify(data, null, '  ');
            let blob = new Blob([json], {type: 'application/json'});
            fileSaver.saveAs(blob, fileName + `.json`);
        },

        addCanonicalLinkToHead(obj) {
            if (!!obj && !!obj.canonicalUrl && obj.canonicalUrl.length) {
                let link = document.createElement('link');
                link.setAttribute('hid', 'canonical');
                link.setAttribute('rel', 'canonical');
                link.setAttribute('href', obj.canonicalUrl);
                document.head.appendChild(link);
            }
        },

        getCanonicalLink(obj) {
            if (!!obj && !!obj.canonicalUrl && obj.canonicalUrl.length) {
                return [{
                    rel: 'canonical',
                    href: obj.canonicalUrl
                }]
            }
        },

        getMetaTitle(obj) {
            let title = 'Domki drewniane letniskowe zobacz i zamów | domkidrewniane.org';

            if(obj && obj.metaTitle && obj.metaTitle.length){
                title = obj.metaTitle;
            }

            return title;
        },

        getMeta(obj) {
            let description = 'Producent drewnianych domków letniskowych, skonfiguruj i zamów swój wymarzony domek! Domki całoroczne i ogrodowe, bez pozwolenia, ponad 7000 realizacji, wysoka jakość i najlepsze ceny.';

            if(obj && obj.metaDescription && obj.metaDescription.length){
                description = obj.metaDescription;
            }

            return [
                {
                    hid: 'description',
                    name: 'description',
                    content: description
                },
                {
                    hid: 'keywords',
                    name: 'keywords',
                    content: 'Drewniane, domki, z bali, całoroczne, altany, garaże, wiaty, letniskowe, narzędziowe, ogrodowe, garaże'
                }
            ]
        },

        devLog(label, data, customColors = null) {
            if (process.env.debug) {
                let css = 'font-size: 13px; background: #003e4e; color: white; display: block; padding: 3px 5px 3px 0';

                if (!!customColors) {
                    css = 'font-size: 13px; background: ' + customColors.bg + '; color: ' + customColors.color + '; display: block; padding: 3px 5px 3px 0';
                }

                console.log('%c devLog:%s', css, label, '\n', data);
            }
        },

        assignDataFromGUS(resp, _this) {
            if (!!resp) {
                if (!!resp.companyName) {
                    _this.company = resp.companyName;
                }

                if (!!resp.postcode) {
                    _this.postcode = resp.postcode;
                }

                if (!!resp.city) {
                    _this.city = resp.city;
                }

                if (!!resp.street) {
                    _this.street = resp.street;

                } else if (!!resp.city) {
                    _this.street = resp.city;
                }

                if (!!resp.houseNumber || resp.houseNumber === '0') {
                    _this.street += ' ' + resp.houseNumber;
                }

                if (!!resp.flatNumber || resp.flatNumber === '0') {
                    _this.street += '/' + resp.flatNumber;
                }

                _this.countryCode = 'PL';
            }

            _this.loadingDataFromGUS = false;
        },

        getProductsCategoryHref(cat){
            if (cat.link) {
                return  `/products-list/${cat.link}`;

            } else if (cat.code) {
                return  `/products-list/${cat.code}`;
            }
        },

        getArticleHref(art){
            if (art.slug) {
                return  `/article/${art.slug}`;

            } else if (art.code) {
                return  `/article/${art.code}`;
            }
        },

    }
});
