import Vue from 'vue'

Vue.mixin({
    computed: {
        _limit() {
            return this.$store.state.products_list.products.limit;
        },

        _page() {
            return this.$store.state.products_list.products.page;
        },

        _productPhrase() {
            return this.$store.state.products_list.products.productPhrase;
        },

        _currentProductsCategoryCode() {
            return this.$store.state.categories.currentProductsCategoryCode;
        },

        _productsListRequest() {
            return this.$store.state.products_list.products.productsListRequest;
        },

        _priceFilter() {
            return this.$store.state.product_filters.priceFilter;
        },

        _sortMethod() {
            return this.$store.state.products_list.sortMethod;
        },

        _customFilters() {
            return this.$store.state.product_filters.customFilters;
        }
    },

    methods: {
        updateCategoryList() {
            this.$axios.$get('/shop-api/taxons/')
                .then(response => {
                    this.$store.commit(
                        'categories/updateCategoryList',
                        {response}
                        );
                })
                .catch(err => this.devLog('updateCategoryList err', err));
        },

        selectProductCategory(category, paramIsString = false) {
            let temp = '';

            if (paramIsString) {
                temp = category;

            } else if (!!category.link) {
                temp = category.link;

            } else if (!!category.code) {
                temp = category.code;
            }

            window.history.replaceState({}, '', temp);

            this.$store.commit(
                'categories/selectProductCategory',
                temp
            );

            this.doHelperFunctionsAssociatedWithSelectingCat();
        },

        doHelperFunctionsAssociatedWithSelectingCat() {
            this.closeMobileMenu();
            this.$store.commit('products_list/setDefaultPagination');
            this.$store.commit('product_filters/setDefaultPriceFilter');
            this.$store.commit('product_filters/cleanCustomFilters');
            this.updateProductsList(null);
        },

        /**
         * @param {String} productPhrase - the word we will be looking for products
         * @param {Number} limit - number of max products on page
         * @param {Number} page - current page of products
         */
        updateProductsList(productPhrase = this._productPhrase, limit = this._limit, page = this._page) {
            this.$store.commit('loading/show', this.$t('updatesTheProductList'));

            if (this._productsListRequest) {
                this.$store.commit('products_list/cancelProductsListRequest');
            }

            this.$store.commit('products_list/setProductsListRequest', this.$axios.CancelToken.source());

            let phrase = !!productPhrase ? '&search=' + productPhrase : '';
            let params = '?page=' + page + '&limit=' + limit + phrase + this.getStringParamsByFilters();

            this.$store.commit('products_list/cleanProductResults');

            this.$axios.$get('/shop-api/product-variants' + params, {cancelToken: this._productsListRequest.token})
                .then(response => {
                    this.$store.commit('products_list/setNullOnProductListRequest');

                    this.$store.commit('products_list/updateProductResults', {
                        response: response
                    });

                    this.$store.commit('loading/hide');
                })
                .catch(err => {
                    //TODO using 'isCanceled' variable is a temporary solution, will be remove when "@idct/axios-jwt-oobe-for-nuxt": "^0.1.2" it be updated
                    let isCanceled = !!err.message && err.message === 'Cannot read property \'status\' of undefined';

                    if (!isCanceled && !this.$axios.isCancel(err)) {
                        this.$store.commit('loading/hide');
                    }
                })
        },

        getStringParamsByFilters() {
            let temp = '';

            if (!!this._currentProductsCategoryCode) {
                temp += '&filters[category][equal]=' + this._currentProductsCategoryCode;
            }

            if (!!this._priceFilter.valuesArray) {
                temp += '&filters[price][gte]=' + this._priceFilter.valuesArray[0] * 100;
                temp += '&filters[price][lte]=' + this._priceFilter.valuesArray[1] * 100;
            }

            if (this._customFilters.length) {

                this._customFilters.forEach(element => {

                    switch (element.type) {
                        case 'BOOLEAN':
                            temp += '&filters[' + element.index + '][' + element.code + '][equal]=' + element.selected;
                            break;

                        case 'STRING':
                            element.selected.forEach((value, value_index) => {
                                temp += '&filters[' + element.index + '_' + value_index + '][' + element.code + '][equal]=' + value.code;
                            });

                            break;

                        case 'SELECT':
                            element.selected.forEach((value, value_index) => {
                                temp += '&filters[' + element.index + '_' + value_index + '][' + element.code + '][equal]=' + value.code;
                            });

                            break;

                        case 'SELECT + IMAGE':
                            element.selected.forEach((value, value_index) => {
                                temp += '&filters[' + element.index + '_' + value_index + '][' + element.code + '][equal]=' + value;
                            });

                            break;

                        case 'INT':
                            temp += '&filters[' + element.index + '][' + element.code + '][gte]=' + element.selected[0];
                            temp += '&filters[' + element.index + '][' + element.code + '][lte]=' + element.selected[1];
                            break;
                    }
                })
            }

            temp += this._sortMethod.method;

            return temp;
        }
    }
});
