export const state = () => ({
  list: []
});

export const mutations = {
  updateBannersList(state, payload){
    state.list = payload;
  }
};


