<template>
  <nav class="position-relative" :class="{ hiddenOnDesktop }" aria-label="Main Menu">
    <b-container class="basic-view d-flex justify-content-start align-items-start">
      <aside class="h-100 d-flex flex-column justify-content-center align-items-start">
        <div class="contact-box">
          <h4 class="fs-14 color-accent">{{ $t('footerTitle1') }}</h4>
          <h3 class="fs-14 m-0">
            <a :href="$t('companyTelephoneHref')" class="pr-4 d-inline-block">
              {{ $t('companyTelephone') }}
            </a>
            <a :href="$t('companySecondTelephoneHref')" class="pr-4 d-inline-block">
              {{ $t('companySecondTelephone') }}
            </a>
          </h3>
        </div>
      </aside>
      <div class="categories-container h-100 d-flex align-items-center flex-wrap flex-grow-1">
        <div v-if="!!formattedCategories"
             v-for="category in formattedCategories"
             :key="category.code"
             class="element d-flex flex-column justify-content-center align-items-center h-100"
        >
          <div class="fake-link" @mouseover="expand(category)">
            <h2 class="fs-14 font-weight-bold color-def m-0">{{ category.shortName }}</h2>
            <h3 v-if="!!category.shortSubname" class="fs-13 m-0">{{ category.shortSubname }}</h3>
          </div>
        </div>
      </div>
    </b-container>
    <transition name="fade" mode="out-in">
      <div v-if="expanded" class="expanded-view position-absolute overflow-hidden scrollable">
        <WcagHeaderNavigation :simple="true" :menu="true"/>
        <search-bar :mobile="true"/>
        <h3 class="mobile-header mt-2 mb-4">{{ $t('myMenu.client') }}:</h3>
        <div class="fs-13 links-container">
          <div class="menu-line bottom-line text-right d-flex justify-content-end align-items-center">
            <span @click="goTo('/contact')" class="lh-1">{{ $t('myMenu.contact') }}</span>
          </div>
        </div>
        <div v-if="!isUserLogged" class="fs-13 links-container">
          <div class="menu-line bottom-line text-right d-flex justify-content-end align-items-center">
            <span @click="goTo('/login')" class="lh-1">{{ $t('layoutHeader.login') }}</span>
          </div>
        </div>
        <div v-else class="fs-13 links-container">
          <div class="menu-line bottom-line text-right d-flex justify-content-end align-items-center">
            <span @click="ordersPanelLinksVisible = !ordersPanelLinksVisible" class="lh-1">
              <fa icon="angle-down" class="icon-flysign" :class="{rotated: ordersPanelLinksVisible}"/>
              {{ $t('orderPanel.panel') }}
            </span>
          </div>
          <b-collapse id="ordersPanelLinks" class="w-100 pl-4" :visible="ordersPanelLinksVisible">
            <div v-for="item in ordersPanelLinks"
                 class="menu-line bottom-line text-right d-flex justify-content-end align-items-center"
            >
              <span @click="goTo(item.to)" class="lh-1">
                {{ $t(item.translationCode) }}
                <fa :icon="item.icon" size="sm" transform="up-1"/>
              </span>
            </div>
          </b-collapse>
          <div class="menu-line bottom-line text-right d-flex justify-content-end align-items-center">
            <span @click="accountProfileLinksVisible = !accountProfileLinksVisible" class="lh-1">
              <fa icon="angle-down" class="icon-flysign" :class="{rotated: accountProfileLinksVisible}"/>
              {{ $t('orderPanel.accountProfile') }}
            </span>
          </div>
          <b-collapse id="ordersPanelLinks" class="w-100 pl-4" :visible="accountProfileLinksVisible">
            <div v-for="item in accountProfileLinks"
                 class="menu-line bottom-line text-right d-flex justify-content-end align-items-center"
            >
              <span @click="goTo(item.to)" class="lh-1">
                {{ $t(item.translationCode) }}
                <fa :icon="item.icon" size="sm" transform="up-1"/>
              </span>
            </div>
          </b-collapse>
          <div class="menu-line text-right d-flex justify-content-end align-items-center">
            <span @click="goTo('/favourite-products')" class="lh-1">{{ $t('layoutHeader.favorite') }}</span>
          </div>
        </div>
        <h3 class="mobile-header mb-5 mt-5">{{ $t('productsCategories') }}:</h3>
        <b-container class="mobile-wrapper h-100 d-flex justify-content-center align-items-center" @mouseleave="fold()">
          <div class="img-container d-flex justify-content-center align-items-center h-100">
            <transition name="fade" mode="out-in">
              <img v-if="thumbnail" :key="thumbnail" :src="thumbnail" alt="kategoria"/>
            </transition>
          </div>
          <div class="d-flex flex-column justify-content-start align-items-start position-relative flex-grow-1 h-100">
            <div class="categories-container w-100 d-flex align-items-center flex-wrap flex-grow-1">
              <div v-if="!!formattedCategories"
                   v-for="category in formattedCategories"
                   :key="category.code"
                   class="element d-flex flex-column justify-content-center align-items-center h-100"
              >
                <nuxt-link class="category-link d-inline-block"
                           :to="getProductsCategoryHref(category)"
                           @click.native="selectProductCategory(category)"
                           @mouseover.native="expand(category)"
                           :class="{
                             active: getActiveClass(category)
                           }"
                >
                  <h2 class="fs-14 font-weight-bold color-def m-0">{{ category.shortName }}</h2>
                  <h3 v-if="!!category.shortSubname" class="fs-13 m-0">{{ category.shortSubname }}</h3>
                </nuxt-link>
                <fa v-if="!!category.children && !!category.children.length"
                    icon="angle-down"
                    class="icon-flysign"
                    :class="{rotated: !!hoveredCat && hoveredCat.code === category.code}"
                    @click="expand(category, true)"
                />
                <div class="mobile-subcategories">
                  <b-collapse :id="category.code" class="w-100"
                              :visible="!!hoveredCat && hoveredCat.code === category.code"
                  >
                    <div class="d-flex flex-column">
                      <nuxt-link v-for="subcategory in category.children"
                                 class="subcategory-link d-inline-block"
                                 :to="getProductsCategoryHref(subcategory)"
                                 @click.native="selectProductCategory(subcategory)"
                                 :key="subcategory.code"
                                 :class="{
                                  visible: !!hoveredCat && hoveredCat.code === category.code,
                                  active: getActiveClass(subcategory)
                                 }"
                      >
                        {{ subcategory.name }}
                      </nuxt-link>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>
            <div class="subcategories-container d-flex justify-content-between align-items-center flex-wrap w-100">
              <transition name="fade" mode="out-in">
                <div v-if="!!hoveredCat"
                     class="wrapper d-flex flex-column align-items-stretch w-100 h-100"
                     :key="hoveredCat.code"
                >
                  <div class="first-row d-flex align-items-stretch flex-grow-1">
                    <nuxt-link v-for="subcategory in subcategoriesFirstRow"
                               :key="subcategory.code"
                               :to="getProductsCategoryHref(subcategory)"
                               class="fs-13 d-flex justify-content-center align-items-center flex-grow-1 position-relative"
                               @mouseover.native="seThumbnail(subcategory.images)"
                               @click.native="selectProductCategory(subcategory)"
                    >
                      {{ subcategory.name }}
                    </nuxt-link>
                  </div>
                  <div v-if="subcategoriesSecondRow.length" class="second-row d-flex align-items-stretch flex-grow-1">
                    <nuxt-link v-for="subcategory in subcategoriesSecondRow"
                               :key="subcategory.code"
                               :to="getProductsCategoryHref(subcategory)"
                               class="fs-13 d-flex justify-content-center align-items-center flex-grow-1 position-relative"
                               @mouseover.native="seThumbnail(subcategory.images)"
                               @click.native="selectProductCategory(subcategory)"
                    >
                      {{ subcategory.name }}
                    </nuxt-link>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </b-container>
        <div class="contact-box">
          <h4 class="fs-14 color-accent">{{ $t('footerTitle1') }}</h4>
          <h3 class="fs-14 m-0">
            <a :href="$t('companyTelephoneHref')" class="pr-4 d-inline-block">
              {{ $t('companyTelephone') }}
            </a>
            <a :href="$t('companySecondTelephoneHref')" class="pr-4 d-inline-block">
              {{ $t('companySecondTelephone') }}
            </a>
          </h3>
        </div>
        <div class="bg-mask position-relative"></div>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <div v-if="expanded" class="shadow-element position-absolute w-100"></div>
    </transition>
  </nav>
</template>

<script>
  import SearchBar from '~/components/UtilityComponents/SearchBar';
  import WcagHeaderNavigation from "~/components/LayoutComponents/WcagHeaderNavigation";

  export default {
    name: "MainNav",
    props: {
      hiddenOnDesktop: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        hoveredCat: null,
        thumbnail: null,
        subcategoriesFirstRow: [],
        subcategoriesSecondRow: [],
        ordersPanelLinks: [
          {translationCode: 'orderPanel.yourOrders', to: '/my/orders', icon: 'check'},
          {translationCode: 'orderPanel.yourOffers', to: '/my/offers', icon: 'star'},
          {translationCode: 'orderPanel.payments', to: '/my/payments', icon: 'credit-card'},
          {translationCode: 'orderPanel.invoices', to: '/my/invoices', icon: 'file'},
          {translationCode: 'orderPanel.attachments', to: '/my/instructions', icon: 'wrench'},
          {translationCode: 'orderPanel.complaints', to: '/my/complaints', icon: 'exclamation-triangle'}
        ],
        ordersPanelLinksVisible: false,
        accountProfileLinks: [
          {translationCode: 'orderPanel.yourData', to: '/my/profile-data', icon: 'user'},
          {translationCode: 'orderPanel.deliveryAddresses', to: '/my/addresses', icon: 'map-marker-alt'},
          {translationCode: 'profilePanel.passChange', to: '/my/password', icon: 'lock'},
          {translationCode: 'orderPanel.newsletter', to: '/my/newsletter', icon: 'at'}
        ],
        accountProfileLinksVisible: false,
      }
    },
    components: {
      SearchBar,
      WcagHeaderNavigation
    },
    computed: {
      categories() {
        return this.$store.state.categories.list;
      },
      formattedCategories() {
        let categories = this.categories;

        if (!!categories) {
          categories.forEach(e => {
            let words = e.name.split(" ");
            e.shortName = words[0];
            e.shortSubname = e.name.split(e.shortName)[1];
          });
        }

        return categories;
      },
      expanded() {
        return this.$store.state.categories.openedMenu;
      }
    },
    methods: {
      getActiveClass(category) {
        return category.code === this._currentProductsCategoryCode && this.$route.fullPath.includes('products-list');
      },
      expand(category, isToggler) {
        !!screen && !!screen.width && screen.width < 751 ? '' : this.$store.commit('categories/openMenu');

        if (isToggler && !!this.hoveredCat && category.code === this.hoveredCat.code) {
          this.hoveredCat = null;

        } else {
          this.hoveredCat = category;
          this.seThumbnail(category.images);
          this.divideSubcategoriesOnRow(category.children);
        }
      },

      fold() {
        !!screen.width && screen.width < 751 ? '' : this.$store.commit('categories/closeMenu');
      },

      seThumbnail(images) {
        if(images && images[0] && images[0]['cachedPath']){
          this.thumbnail = `${images[0]['cachedPath']}_category`;
        } else {
          this.thumbnail = null;
        }
      },

      divideSubcategoriesOnRow(subcategories) {
        this.subcategoriesFirstRow = [];
        this.subcategoriesSecondRow = [];

        switch (subcategories.length) {
          case 0:
            break;
          case 1:
            this.subcategoriesFirstRow.push(subcategories[0]);
            break;
          case 2:
            this.subcategoriesFirstRow.push(subcategories[0]);
            this.subcategoriesSecondRow.push(subcategories[1]);
            break;
          default:
            let indexToSplit = Math.round(subcategories.length / 2);

            this.subcategoriesFirstRow = subcategories.slice(0, indexToSplit);
            this.subcategoriesSecondRow = subcategories.slice(indexToSplit);
        }
      }
    },
    created(){
      this.$store.commit('categories/closeMenu');
    }
  }
</script>

<style lang="scss" scoped>
  $rowHeight: 70px;
  nav {
    margin-bottom: 30px;

    &.hiddenOnDesktop{
      display: none;
    }

    .categories-container {
      justify-content: stretch;

      .element {
        flex-grow: 1;

        .icon-flysign {
          display: none;
        }

        .mobile-subcategories {
          display: none;
        }

        h2 {
          transition: color $transitionStandardTime;
          text-transform: uppercase;
        }

        h3 {
          color: $textColor2;
          letter-spacing: 0.5px;
        }
      }
    }

    .basic-view {
      height: $rowHeight;

      aside {
        width: calc(25% - 22px);
        padding-left: 15px;
        border-right: $line;

        .contact-box {
          h4 {
            margin-bottom: 5px;
          }

          h3 {
            a:first-child {
              margin-bottom: 2px;
            }
          }

          .text {
            margin: 5px 0;
          }
        }
      }

      .fake-link {
        cursor: pointer;
      }
    }

    .expanded-view {
      left: 0;
      top: 0;
      width: 100%;
      height: calc(3 * #{$rowHeight});
      z-index: 3;
      background: $textColor5;

      .mobile-header {
        display: none;
      }

      .links-container, .contact-box {
        display: none;
      }

      .search-bar-container {
        display: none !important;
      }

      .container {
        .img-container {
          width: calc(25% - 23px);
          padding: 25px 25px 25px 15px;

          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }

        .categories-container {
          height: $rowHeight;
          background: $textColor5;
          border-left: $line;

          .category-link {
            cursor: pointer;

            &:hover h2 {
              color: $textColor6;
            }
          }
        }

        .subcategories-container {
          height: calc(2 * #{$rowHeight});
          background-color: $textColor1;

          .wrapper {
            justify-content: stretch;

            .first-row {
              justify-content: stretch;
            }

            .second-row {
              justify-content: stretch;
              border-top: $line2;
            }

            a {
              color: $textColor12;
              letter-spacing: 0.46px;
              border-left: $line2;

              &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                height: 2px;
                width: 0;
                background: $textColor17;
                transition: width $transitionStandardTime;
                transform: translateX(-50%);
              }

              &:hover {
                color: $textColor5;

                &:before {
                  width: 100%;
                }
              }

              &:first-child {
                border-left: unset;
              }
            }
          }
        }
      }

      .bg-mask:before {
        content: '';
        position: absolute;
        width: 100vw;
        height: calc(2 * #{$rowHeight});
        background-color: $textColor1;
        z-index: -1;
        transform: translate(50%, -100%);
      }
    }

    .shadow-element {
      left: 0;
      top: calc(3 * #{$rowHeight} - 10px);
      height: 10px;
      box-shadow: 0 6px 10px rgba(41, 127, 202, 0.1);
      z-index: 3;
    }

    @media (max-width: 830px) {
      .contact-box {
        h4 {
          font-size: 1.25rem;
        }
      }
    }

    @media (max-width: 750px) {
      margin-bottom: 0;

      &.hiddenOnDesktop{
        display: block;
      }

      .basic-view {
        display: none !important;
      }

      .expanded-view {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: calc(100vh - 81px) !important;
        z-index: 201;
        background: $textColor;
        padding: 15px;
        position: fixed !important;
        top: 81px !important;
        transition: all 0.2s !important;

        &.scrollable {
          overflow-y: scroll !important;
          overflow-x: hidden !important;
        }

        .contact-box {
          display: block;

          h4 {
            color: $textColor5;
            font-size: 1.6rem !important;
          }

          h3 {
            display: inline-block !important;

            span {
              display: inline-block !important;
              color: $textColor5;
              font-size: 1.6rem !important;
            }

            a {
              color: $textColor6;
              font-size: 1.6rem !important;
              cursor: pointer;
              transition: all $transitionStandardTime;
              opacity: 0.9;

              &:hover {
                opacity: 1;
              }
            }
          }
        }

        .search-bar-container {
          display: block !important;
        }

        .links-container {
          display: block !important;
          padding: 0 0 0 5px;

          .menu-line {
            border: unset !important;
            justify-content: flex-start !important;

            span {
              display: flex;
              justify-content: center;
              align-items: flex-end;
              flex-direction: row-reverse;
              color: $textColor5;
              margin: 10px;
              font-size: 1.6rem !important;
              cursor: pointer;
              transition: all $transitionStandardTime;

              &:hover {
                color: $textColor6;
              }

              svg {
                margin-right: 10px;
                width: 18px;
              }
            }
          }
        }

        .icon-flysign {
          display: inline-block !important;
          color: $textColor5 !important;
          transition: all $transitionStandardTime;
          cursor: pointer;
          margin: 0 10px;
          font-size: 2rem;

          &.rotated {
            transform: rotate(180deg);
          }

          &:hover {
            color: $textColor6;
          }
        }

        .mobile-header {
          display: block !important;
          color: white;
          font-size: 1.6rem;
          border-bottom: 1px solid white;
          padding-bottom: 8px;
          width: calc(100% + 15px);
          user-select: none;
          font-weight: 400;
        }

        .mobile-wrapper {
          flex-direction: column;
          margin-bottom: 20px;

          .img-container {
            display: none !important;
          }

          > :nth-child(2) {
            width: 100%;
            flex-direction: row !important;

            .categories-container {
              height: unset !important;
              flex-direction: column;
              border: unset !important;
              background: unset !important;
              flex-grow: unset !important;
              width: 100% !important;
              padding-left: 15px;

              .element {
                height: unset !important;
                flex-grow: unset !important;
                flex-direction: row !important;
                justify-content: flex-start !important;
                flex-wrap: wrap;
                width: 100%;
                margin: 0 20px 20px 0;

                a {
                  &:hover {
                    h2, h3 {
                      color: $textColor6 !important;
                    }
                  }

                  &.active {
                    pointer-events: none;

                    > h2, > h3 {
                      color: $textColor18 !important;
                    }
                  }

                  h2, h3 {
                    color: $textColor5 !important;
                    display: inline-block !important;
                    text-transform: none;
                    font-size: 1.6rem !important;
                    font-weight: 400 !important;
                    transition: all $transitionStandardTime !important;
                  }
                }

                .mobile-subcategories {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  width: 100%;

                  .subcategory-link {
                    font-size: 1.4rem;
                    color: $textColor5;
                    transition: all $transitionStandardTime;
                    margin: 10px 10px 0 20px;
                    opacity: 0;

                    &.active {
                      color: $textColor18 !important;
                      pointer-events: none;
                    }

                    &.visible {
                      opacity: 1;
                    }

                    &:hover {
                      color: $textColor6;
                    }
                  }
                }
              }
            }

            .subcategories-container {
              display: none !important;
            }
          }
        }

        .bg-mask {
          display: none !important;
        }
      }
    }
  }
</style>
