export const state = () => ({
    productRows: [],
    // token: null,
    totals: null,
    id: null,
    loading: false,
    addingItemInprogress: false,
    basketRequest: null,
    couponCode: null
});

export const mutations = {
    setAddingItemInprogress(state, payload) {
        state.addingItemInprogress = payload;
    },

    getNewBasketAsQuest(state, payload) {
        // state.token = payload.tokenValue;
        state.id = payload.id;
        state.productRows = [];
    },

    updateBasket(state, payload) {
        state.productRows = payload.items;
        state.totals = payload.totals;
        state.id = payload.id;

        if(payload.couponCode){
            state.couponCode = payload.couponCode;

        } else {
            state.couponCode = null;
        }
    },

    cleanBasketAfterLogout(state) {
        state.productRows = null;
        state.totals = null;
        state.id = null;
        state.addingItemInprogress = false;
        state.basketRequest = null
    },

    updateShippingPrice(state, payload) {
        state.totals.shipping = payload.shipping;
        state.totals.total = payload.total;
    },

    updateBasketAfterLogged(state, payload) {
        // state.token = payload.tokenValue;
        state.productRows = payload.items;
        state.id = payload.id;
        state.totals = payload.totals;
    },

    setBasketRequest(state, request) {
        state.basketRequest = request;
    },

    cancelBasketRequest(state) {
        if (
            state.basketRequest.hasOwnProperty("cancel") &&
            state.basketRequest.cancel === "function"
        ) {
            state.basketRequest.cancel();
        }

        state.basketRequest = null;
    }
};
