export const state = () => ({
  data: null,
  reviews: {
    page: 1,
    limit: 3,
    pages: 1,
    total: 0,
    _links: null,
    items: []
  },
  href: null,
  parentProductData: null
});

export const mutations = {
  cleanParentProductData(state) {
    state.parentProductData = null;
  },

  updateParentProductData(state, payload) {
    state.parentProductData = payload;
  },

  cleanProductData(state) {
    state.data = null;
    state.href = null;
    state.reviews = {
      page: 1,
      limit: 3,
      pages: 1,
      total: 0,
      _links: null,
      items: []
    };
  },

  setHref(state, href) {
    state.href = href;
  },

  updateProductDataByCode(state, payload) {
    state.data = payload;
  },

  updateProductDataByAlias(state, payload) {
    state.data = payload;
  },

  updateProductReviews(state, payload) {
    state.reviews = {
      page: payload.page,
      limit: payload.limit,
      pages: payload.pages,
      total: !!payload.total ? payload.total : 0,
      _links: null,
      items: payload._embedded.items
    };
  },

  toggleOption(state, optCode) {
    let option = state.data.options.find(option => {
      return option.code === optCode
    });

    if (option.active && !option.required) {
      option.active = false;

      if (!!option.suboptions) {

        option.suboptions.forEach(suboption => {
          suboption.active = false
        });
      }

    } else {
      option.active = true;

      if (!!option.suboptions && !!option.suboptions[0]) {
        option.suboptions[0].active = true;
      }
    }
  },

  toggleOptionValue(state, payload) {
    let optCode = payload.optCode;
    let optValCode = payload.optValCode;

    let option = state.data.options.find(option => {
      return option.code === optCode
    });

    let optionValue = option.suboptions.find(element => {
      return element.code === optValCode
    });

    let attribute = optionValue.options[0] || null;

    if (optionValue.active) {
      optionValue.active = false;
      !!attribute ? attribute.active = false : '';

    } else {

      option.suboptions.forEach(element => {
        element.active = false;
        !!element.options[0] ? element.options[0].active = false : '';
      });

      optionValue.active = true;
      !!attribute ? attribute.active = true : '';

      option.active = true;
    }
  }
};
