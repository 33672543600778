export default function ({store, redirect, route}) {
    if (!store.state.user.token) {
        if (route.fullPath === '/my') {
            return redirect({
                path: '/login',
                query: {
                    redirect: '/my/orders'
                }
            });

        } else {
            return redirect({
                path: '/login',
                query: {
                    redirect: route.fullPath
                }
            });
        }

    } else if(route.fullPath === '/my'){
        return redirect('/my/orders');
    }
}


