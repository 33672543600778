export const state = () => ({
  list: []
});

export const mutations = {
  synchroniseDebugData(state, payload) {
    let debugDataIndex = state.list.findIndex(item => item.id === payload.id);

    if (debugDataIndex === -1) {
      state.list.push(payload);

    } else {
      state.list[debugDataIndex] = payload;
    }
  }
};

