export const state = () => ({
    status: false
});

export const mutations = {
    setDownloadingStatus(state, payload) {
        state.status = payload;
    },
};


