import Vue from 'vue'

Vue.mixin({
    computed: {
        _basketRequest() {
            return this.$store.state.basket.basketRequest;
        }
    },
    methods: {
        getNewBasketAsQuest() {
            this.$store.commit('loading/show', this.$t('notify.gettingNewBasket'));
            this.setBasketRequest();

            this.$axios.$post('/shop-api/carts')
                .then(response => {
                    this.$store.commit('basket/getNewBasketAsQuest', response);
                    this.$store.commit('basket_token/setBasketToken', response);
                })
                .catch(err => this.devLog('getNewBasketAsQuest', err))
                .then(() => {
                    this.cancelBasketRequest();
                    this.$store.commit('loading/hide')
                })
        },

        synchroniseAnonymousBasketWithLoggedBasket(token, cb) {
            this.$store.commit('loading/show', this.$t('notify.syncCart'));

            this.$axios.$put('/shop-api/order/' + token + '/synchronize-cart')
                .then(response => this.updateBasketAfterLogged(cb))
                .catch(err => {
                    this.$notify({
                        type: 'error',
                        title: 'Synchronizacja zawartości koszyka zakończyła się niepowodzeniem.',
                        text: 'Spróbuj ponownie lub skontaktuj się z administratorem strony.'
                    });

                    this.devLog('synchroniseAnonymousBasketWithLoggedBasket', err);
                    this.$store.commit('loading/hide');
                })
        },

        updateBasketAfterLogged(cb = () => {}) {
            this.setBasketRequest();
            this.$store.commit('loading/show', this.$t('notify.updatesCartStatusAfterLogging'));

            this.$axios.$post('/shop-api/customer/carts')
                .then(response => {
                    this.$store.commit('basket/updateBasketAfterLogged', response);
                    this.$store.commit('basket_token/setBasketToken', response);
                    cb();
                })
                .catch(err => this.devLog('updateBasketAfterLogged', err))
                .then(() => {
                    this.cancelBasketRequest();
                    this.$store.commit('loading/hide')
                })
        },

        cancelBasketRequest() {
            this.$store.commit('basket/cancelBasketRequest');
        },

        setBasketRequest() {
            this.$store.commit('basket/setBasketRequest', this.$axios.CancelToken.source());
        },

        updateBasket(token) {
            if (!this._basketRequest) {
                this.setBasketRequest();
                this.$store.commit('loading/show', this.$t('notify.updatesCartStatus'));

                this.$axios.$get(
                    '/shop-api/carts/' + token,
                    {cancelToken: this._basketRequest.token}
                )
                    .then(response => this.$store.commit('basket/updateBasket', response))
                    .catch(err => {
                        if (this.$axios.isCancel(err)) {
                            // console.log('request cancelled');
                        } else {
                            this.devLog('updateBasket', err);

                            if (err.response.status === 404) {
                                !!this.$store.state.user.token ? this.updateBasketAfterLogged() : this.getNewBasketAsQuest();
                            }
                        }
                    })
                    .then(() => {
                        this.cancelBasketRequest();
                        this.$store.commit('loading/hide')
                    })
            }
        },

        addToBasketProduct(productCode, quantity, requestedAmount, token, variantCode) {
            this.setBasketRequest();
            this.$store.commit('loading/show', this.$t('notify.addingProductToTheCart'));

            let temp = {
                productCode: productCode,
                quantity: quantity,
                requestedAmount: requestedAmount
            };

            !!variantCode ? temp.variantCode = variantCode : '';

            this.$axios.$post(
                '/shop-api/carts/' + token + '/items',
                temp
            )
                .then(response => this.$store.commit('basket/updateBasket', response))
                .catch(err => {
                    this.devLog('addToBasketProduct', err);

                    if (err.response.status === 400) {
                        if (this.$store.state.user.token) {
                            this.updateBasketAfterLogged();

                        } else {
                            this.getNewBasketAsQuest();
                        }

                        this.$notify({
                            type: 'error',
                            title: this.$t('notify.internalError'),
                            text: this.$t('notify.tryAgain')
                        });
                    }
                })
                .then(() => {
                    this.cancelBasketRequest();
                    this.$store.commit('loading/hide')
                })
        },

        addToBasketConfigurableProduct(token, quantity, product) {
            this.setBasketRequest();
            this.$store.commit('loading/show', this.$t('notify.addingConfigurableProductToTheCart'));

            this.$axios.$post(
                '/shop-api/product-variant/configure/' + token,
                product
            )
                .then(response => this.$store.commit('basket/updateBasket', response))
                .catch(err => {
                    this.devLog('addToBasketConfigurableProduct', err);

                    if (err.response.status === 400) {
                        if (this.$store.state.user.token) {
                            this.updateBasketAfterLogged();

                        } else {
                            this.getNewBasketAsQuest();
                        }

                        this.$notify({
                            type: 'error',
                            title: this.$t('notify.internalError'),
                            text: this.$t('notify.tryAgain')
                        });
                    }

                })
                .then(() => {
                    this.cancelBasketRequest();
                    this.$store.commit('loading/hide')
                })
        },

        deleteProductFromBasket(token, id) {
            if (!this._basketRequest) {
                this.setBasketRequest();
                this.$store.commit('loading/show', this.$t('notify.removingProductFromTheCart'));

                this.$axios.$delete(
                    '/shop-api/carts/' + token + '/items/' + id,
                )
                    .then(response => this.$store.commit('basket/updateBasket', response))
                    .catch(err => {
                        this.devLog('deleteProductFromBasket', err);

                        if (err.response.status === 400) {
                            if (this.$store.state.user.token) {
                                this.updateBasketAfterLogged();

                            } else {
                                this.getNewBasketAsQuest();
                            }

                            this.$notify({
                                type: 'error',
                                title: this.$t('notify.internalError'),
                                text: this.$t('notify.tryAgain')
                            });
                        }

                    })
                    .then(() => {
                        this.cancelBasketRequest();
                        this.$store.commit('loading/hide')
                    })
            }
        },

        changeProductAmountInBasket(token, id, quantity, requestedAmount) {
            if (!this._basketRequest) {
                this.setBasketRequest();
                this.$store.commit('loading/show', this.$t('notify.changingQuantityProductInTheCart'));

                this.$axios.$put(
                    '/shop-api/carts/' + token + '/items/' + id,
                    {
                        quantity,
                        requestedAmount
                    }
                )
                    .then(response => this.$store.commit('basket/updateBasket', response))
                    .catch(err => {
                        this.devLog('changeProductAmountInBasket', err);

                        if (err.response.status === 400) {
                            if (this.$store.state.user.token) {
                                this.updateBasketAfterLogged();

                            } else {
                                this.getNewBasketAsQuest();
                            }

                            this.$notify({
                                type: 'error',
                                title: this.$t('notify.internalError'),
                                text: this.$t('notify.tryAgain')
                            });
                        }

                    })
                    .then(() => {
                        this.cancelBasketRequest();
                        this.$store.commit('loading/hide')
                    })
            }
        }
    }
});
