<template>
  <div class="lang_options-container d-flex align-items-end justify-content-between position-relative">
    <span class="active-lang fs-13 text-uppercase font-weight-bold">{{$t($i18n.locale)}}</span>
    <fa :icon="['fas', 'angle-down']" style="font-size: 14px" class="icon-bottom_arrow"/>
    <div class="wrapper d-flex flex-column position-absolute">
      <div v-for="lang in $i18n.locales"
           :key="lang.code"
           @click="changeLocale(lang)"
           class="lang fs-13 d-flex align-items-center justify-content-center"
      >
        <span class="text-uppercase font-weight-bold">{{lang.code}}</span>-<span class="subtitle">{{lang.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "LangOptions",
    methods: {
      setLocaleIso(payload){
        this.$store.commit('basic_data/setLocaleIso', payload);
      },
      changeLocale(lang) {
        this.$i18n.setLocale(lang.code);
        this.setLocaleIso(lang.iso);
      }
    },
  }
</script>

<style scoped lang="scss">
  .lang_options-container {
    width: 30px;
    margin: 25px 0 0 29px;

    &:hover .wrapper {
      visibility: visible;
      opacity: 1;
      top: 100%;
    }

    .active-lang, .icon-bottom_arrow {
      color: $textColor6;
      cursor: pointer;
    }

    .icon-bottom_arrow {
      padding-bottom: 1px;
    }

    .wrapper {
      border: $border1;
      border-radius: 2px;
      background: $textColor5;
      top: calc(100% + 10px);
      left: -13px;
      transition: visibility $transitionStandardTime, opacity $transitionStandardTime, top $transitionStandardTime;
      visibility: hidden;
      opacity: 0;
      z-index: 1;

      .lang {
        cursor: pointer;
        padding: 8px 12px;
        color: $textColor1;
        letter-spacing: 0.5px;
        transition: color $transitionStandardTime;

        &:hover {
          color: $textColor6;
        }

        .subtitle {
          font-weight: 500;
        }
      }
    }
  }
</style>
