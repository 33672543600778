<template>
  <div class="configuration-process" :class="{preventScroll: isMobileModalOpen, 'wcag-contrast': contrastMode}">
    <loading/>
    <notifications/>
    <layout-header-configuration-process/>
    <nuxt/>
    <layout-footer/>
    <layout-logo-imgs />
  </div>
</template>

<script type="text/javascript">
  import LayoutHeaderConfigurationProcess from '~/components/LayoutComponents/LayoutHeaderConfigurationProcess';
  import LayoutFooter from '~/components/LayoutComponents/LayoutFooter';
  import Loading from '~/components/UtilityComponents/Loading';
  import LayoutLogoImgs from '~/components/LayoutComponents/LayoutLogoImgs';

  export default {
    components: {
      LayoutHeaderConfigurationProcess,
      LayoutFooter,
      Loading,
      LayoutLogoImgs,
    }
  }
</script>

<style lang="scss">
  @media (max-width: 1200px) {
    .configuration-process {
      .container {
        padding: 0 !important;
      }
    }
  }
</style>
