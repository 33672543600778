export const state = () => ({
    isMobileFiltersOpen: false,
    priceFilter: {
        title: 'Cena',
        unit: 'PLN',
        valuesArray: null
    },
    customFilters: []
});

export const mutations = {
    openMobileFilters(state) {
        state.isMobileFiltersOpen = true;
    },

    closeMobileFilters(state) {
        state.isMobileFiltersOpen = false;
    },

    setPriceFilterRange(state, payload) {
        state.priceFilter.unit = payload.unit;
        state.priceFilter.valuesArray = [payload.valuesArray[0], payload.valuesArray[1]];
    },

    setDefaultPriceFilter(state) {
        state.priceFilter.valuesArray = null;
    },

    cleanCustomFilters(state) {
        state.customFilters = [];
    },

    updateCustomFilter(state, payload) {
        let index = state.customFilters.findIndex(element => element.code === payload.code);

        if (payload.type === 'INT') {
            if (index === -1) {
                if (payload.selected !== null) {
                    state.customFilters.push(payload);
                }
            } else {
                if (payload.selected !== null) {
                    state.customFilters[index] = payload;
                } else {
                    state.customFilters.splice(index, 1);
                }
            }
        } else if (index === -1) {
            state.customFilters.push(payload);
        } else if (payload.type === 'BOOLEAN') {
            payload.selected === null ? state.customFilters.splice(index, 1) : state.customFilters[index] = payload;
        } else {
            payload.selected.length ? state.customFilters[index] = payload : state.customFilters.splice(index, 1);
        }
    }
};

