import Vue from 'vue';

Vue.mixin({
        methods: {
            updateSpecialOffersList(
                successfulCallback = () => {},
                failCallback = () => {}
            ) {
                this.$axios.$get(
                    '/shop-api/promotions/special-offers',
                    {params: {limit: 3}}
                )
                    .then(response => {
                        //filter offers by stock amount
                        let items = response._embedded.items
                            .filter(item => item.remained && item.remained > 0);

                        this.$store.commit(
                            'featured_products/updateSpecialOffersList',
                            items
                        );

                        successfulCallback();
                    })
                    .catch(err => {
                        this.devLog('updateSpecialOffersList err', err);
                        failCallback();
                    });
            },

            updateFeaturedProductsList(
                successfulCallback = () => {},
                failCallback = () => {}
            ) {
                this.$axios.$get(
                    'shop-api/product-variant-recommended',
                    {params: {limit: 20}}
                )
                    .then(response => {
                        this.$store.commit(
                            'featured_products/updateFeaturedProductsList',
                            response._embedded.items
                        );

                        successfulCallback();
                    })
                    .catch(err => {
                        this.devLog('updateFeaturedProductsList err', err)
                        failCallback();
                    });
            }
        }
    }
);
