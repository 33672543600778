import Vue from 'vue';

Vue.mixin({
  methods: {
    addProductToFavourite(code, onDone) {
      this.$store.commit('favourite_products/startLoading');
      this.$axios.$post('/shop-api/favorite/add/variant/' + code)
        .then(() => {
          this.updateFavouriteProductsList(() => {
            onDone();
          });
        })
        .catch(err => {
          console.log('err', err);
          onDone();
        })
    },

    removeProductFromFavourite(code, onDone) {
      this.$store.commit('favourite_products/startLoading');
      this.$axios.$delete('/shop-api/favorite/remove/variant/' + code)
        .then(() => {
          this.updateFavouriteProductsList(() => {
            onDone();
          });
        })
        .catch(err => {
          console.log('err', err);
          onDone();
        });
    },

    updateFavouriteProductsList(onDone = () => {}) {
      if(!this.$store.state['favourite_products'].loading) {
        this.$store.commit('favourite_products/startLoading');
      }

      this.$axios.$get('/shop-api/favorite?page=1&limit=100')
        .then(response => {
          if(response && response._embedded.items.length >= 0) {
            this.$store.commit('favourite_products/updateList', response._embedded.items);
          }
        })
        .catch(err => {
          console.log('err', err);
        })
        .then(() => {
          onDone();
          this.$store.commit('favourite_products/stopLoading');
        });
    }
  }
});
