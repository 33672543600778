export const state = () => ({
  complaintsList: [],
  complaintDetails: null,
  reasonsListByRefundType: [],
  reasonsListByReturnType: [],
});

export const mutations = {
  updateComplaintsList(state, payload){
    state.complaintsList = payload;
  },

  updateComplaintDetails(state, payload){
    state.complaintDetails = payload[0];
  },

  updateReasonsListByRefundType(state, payload){
    state.reasonsListByRefundType = payload;
  },

  updateReasonsListByReturnType(state, payload){
    state.reasonsListByReturnType = payload;
  }
};


