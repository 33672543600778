<template>
  <div class="hamburger-btn" :class="{open}" @click="toggleMenu">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script>
  export default {
    name: "HamburgerBtn",
    computed: {
      open() {
        return this.$store.state.categories.openedMenu
      }
    },
    methods: {
      toggleMenu() {
        this.$store.commit('categories/toggleMenu');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .hamburger-btn {
    display: none;
    width: 30px;
    height: 22px;
    position: relative;
    margin: 10px;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: $textColor;
      border-radius: 4px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 9px;
      }

      &:nth-child(3) {
        top: 18px;
      }
    }

    &.open span {
      background: $textColor6;

      &:nth-child(1) {
        top: 9px;
        transform: rotate(135deg);
      }

      &:nth-child(2) {
        opacity: 0;
        left: -30px;
      }

      &:nth-child(3) {
        top: 9px;
        transform: rotate(-135deg);
      }
    }

    @media (max-width: 750px) {
      display: inline-block;
    }

    @media(max-width: 400px) {
      width: 24px;
      height: 17px;
      margin: 10px;

      span {
        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          top: 7px;
        }

        &:nth-child(3) {
          top: 14px;
        }
      }
    }
  }
</style>
