export const state = () => ({
  sections: [],
  searchResults: null,
  phrase: null
});

export const mutations = {
  updateHelpCenterSections(state, payload) {
    payload.forEach(section => section.articles = []);
    state.sections = payload;
  },

  assignArticlesToSections(state, payload) {
    let section = state.sections.find(section => section.code === payload.section_code);

    if (!!section) {
      section.articles.push(...payload.articles)
    }
  },

  updateSearchResults(state, payload) {
    state.searchResults = payload.articles;
    state.phrase = payload.phrase;
  },

  cleanSearchResults(state) {
    state.searchResults = null;
    state.phrase = null;
  }
};

