<template>
  <div>
    <header class="fs-14 simple-header">
      <WcagHeaderNavigation/>

      <div class="container bottom-line">
        <div class="logo-container h-row">
          <nuxt-link to="/" class="logo-btn" title="Domkidrewniane.org">
            <img src="/webp/logo.webp" alt="domkidrewniane.org" width="145" height="58">
          </nuxt-link>
          <!--<lang-options/>-->
        </div>

        <div class="main-header-wrapper">
          <div class="contact-box">
            <h4 class="fs-14 color-accent">{{ $t('footerTitle1') }}</h4>
            <h3 class="fs-14 m-0">
              <a :href="$t('companyTelephoneHref')" class="d-inline-block">
                {{ $t('companyTelephone') }}
              </a>
              <a :href="$t('companySecondTelephoneHref')" class="d-inline-block">
                {{ $t('companySecondTelephone') }}
              </a>
            </h3>
          </div>
          <div class="pr-4 h-row">
            <nav aria-label="Categories Menu">
              <hamburger-btn/>
            </nav>
          </div>
        </div>

      </div>
    </header>
    <main-nav :hidden-on-desktop="true"/>
  </div>
</template>

<script>
  import WcagHeaderNavigation from "~/components/LayoutComponents/WcagHeaderNavigation";
  import HamburgerBtn from '~/components/UtilityComponents/HamburgerBtn';
  import MainNav from '~/components/Categories/MainNav';
  import LangOptions from '~/components/UtilityComponents/LangOptions';

  export default {
    name: 'LayoutHeaderSimple',
    components: {
      WcagHeaderNavigation,
      HamburgerBtn,
      MainNav,
      LangOptions,
    },
  }
</script>

<style lang="scss" scoped>
  header.simple-header {
    .h-row {
      height: $rowHeight;
    }

    .container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .logo-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: calc(25% - 22px);
        padding-left: 15px;
        position: relative;

        .logo-btn {
          display: inline-block;
        }
      }

      .main-header-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        flex-grow: 1;

        .contact-box {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          margin-right: 15px;

          h4 {
            margin-bottom: 5px;
            text-align: right;
          }

          h3 {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start;

            a:first-child{
              margin-bottom: 2px;
            }
          }

          .text {
            margin: 5px 0;
          }
        }

        nav {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
        }
      }
    }

    @media (max-width: 992px) {
      .container {
        max-width: 100% !important;

        > div > div {
          padding-right: 0 !important;
        }
      }
    }

    @media (max-width: 868px) {
      .logo-container {
        border-right: unset !important;
      }

      .main-header-wrapper {
        justify-content: flex-end !important;
      }

      .desktop-visible {
        display: none !important;
      }
    }

    @media(max-width: 812px) {
      .logo-container {
        width: 174px !important;
      }
    }

    @media(max-width: 750px) {
      height: 50px;

      > .container {
        background: white;
        position: fixed;
        box-shadow: 0 6px 10px rgba(41, 127, 202, 0.1);
        left: 0;
        top: 0;
        z-index: 1001;

        .main-header-wrapper .contact-box{
          display: none;
        }
      }
    }

    @media (max-width: 525px) {
      .container {
        padding: 0 !important;
      }
    }

    @media (max-width: 330px) {
      .logo-container {
        padding-left: 0 !important;
        justify-content: center !important;
      }
    }
  }
</style>
