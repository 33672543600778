<template>
  <div :class="{preventScroll: isMobileModalOpen, 'wcag-contrast': contrastMode}" class="user-panel-layout">
    <loading/>
    <notifications/>
    <layout-header/>
    <b-container>
      <div class="bottom-line"></div>
      <div class="mb-5 pb-5">
        <b-row class="side-block mb-5 pb-5 content-wrapper">
          <b-col lg="3" class="custom-mobile-aside">
            <div class="right-line fs-15 user-menu">
              <nav class="cell-space">
                <div class="orders-panel-header">{{ $t('orderPanel.panel') }}</div>
                <div v-for="(link, linkIndex) in ordersPanelLinks"
                     :key="'ordersPanelLinks' + linkIndex"
                     class=" py-3"
                     :class="link.classes"
                >
                  <icon-link-btn :icon="link.icon"
                                 :to="link.to"
                                 :selected="$nuxt.$route.path.split('/')[2] == link.to.split('/')[2]"
                                 user-panel="true"
                  >
                    {{ $t(link.translationCode) }}
                  </icon-link-btn>
                </div>
                <div class="account-profile-header">{{ $t('orderPanel.accountProfile') }}</div>
                <div v-for="(link, linkIndex) in accountProfileLinks"
                     :key="'accountProfileLinks_' + linkIndex"
                     class="user-panel-links py-3"
                     :class="link.classes"
                >
                  <icon-link-btn :icon="link.icon"
                                 :to="link.to"
                                 :selected="$nuxt.$route.path.split('/')[2] == link.to.split('/')[2]"
                                 user-panel="true"
                  >
                    {{ $t(link.translationCode) }}
                  </icon-link-btn>
                </div>
              </nav>
            </div>
          </b-col>
          <b-col lg="9" class="custom-mobile-main">
            <div class="my-content fs-14">
              <nuxt/>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <layout-footer/>
    <layout-logo-imgs />
  </div>
</template>

<script type="text/javascript">
  import LayoutHeader from '~/components/LayoutComponents/LayoutHeader';
  import LayoutFooter from '~/components/LayoutComponents/LayoutFooter';
  import IconLinkBtn from '~/components/UtilityComponents/IconLinkBtn';
  import Loading from '~/components/UtilityComponents/Loading';
  import LayoutLogoImgs from '~/components/LayoutComponents/LayoutLogoImgs';

  export default {
    data() {
      return {
        ordersPanelLinks: [
          {translationCode: 'orderPanel.yourOrders', to: '/my/orders', icon: 'check'},
          {translationCode: 'orderPanel.yourOffers', to: '/my/offers', icon: 'star'},
          {translationCode: 'orderPanel.payments', to: '/my/payments', icon: 'credit-card', classes: {}},
          {translationCode: 'orderPanel.invoices', to: '/my/invoices', icon: 'file', classes: {}},
          {translationCode: 'orderPanel.attachments', to: '/my/instructions', icon: 'wrench', classes: {}},
          {
            translationCode: 'orderPanel.complaints',
            to: '/my/complaints',
            icon: 'exclamation-triangle',
            classes: {'last-in-row': true}
          }
        ],
        accountProfileLinks: [
          {translationCode: 'orderPanel.yourData', to: '/my/profile-data', icon: 'user', classes: {}},
          {translationCode: 'orderPanel.deliveryAddresses', to: '/my/addresses', icon: 'map-marker-alt', classes: {}},
          {translationCode: 'profilePanel.passChange', to: '/my/password', icon: 'lock', classes: {}},
          {translationCode: 'orderPanel.newsletter', to: '/my/newsletter', icon: 'at', classes: {}}
        ]
      }
    },
    components: {
      LayoutFooter,
      LayoutHeader,
      IconLinkBtn,
      Loading,
      LayoutLogoImgs,
    },
  }
</script>

<style lang="scss">
  .user-panel-layout {
    .side-block {
      padding-left: 0;
    }

    .cell-space {
      padding: 20px 15px;
    }

    .user-menu {
      height: 100%;

      nav {
        .orders-panel-header, .account-profile-header {
          padding: 10px 0;
          margin-top: 5px;
          font-size: 1.6rem;
          user-select: none;

          &.account-profile-header {
            margin-top: 30px;
          }
        }
      }
    }

    .my-content {
      padding: 32px 15px 32px 0;
      min-height: 50vh;
    }

    @media (max-width: 993px) {
      .container {
        max-width: 100%;
        padding: 0;

        .row {
          margin: 0 0 30px 0;

          .custom-mobile-aside {
            padding-left: 0;
            max-width: unset;
            width: calc(25% - 8px);
            flex: unset;
          }

          .custom-mobile-main {
            flex: 0 0 75%;
            max-width: 75%;
            padding-right: 5px;
          }
        }
      }
    }

    @media (max-width: 960px) {
      .container {
        .top-row-container .row > div.col-lg-9 {
          padding-right: 0;
        }

        .content-wrapper {
          flex-direction: column;

          .custom-mobile-aside {
            width: 100%;
            padding: 0;
            border-right: unset;
            border-bottom: solid 1px #e4f3fa;

            nav {
              display: flex;
              flex-wrap: wrap;

              > div {
                margin: 0 20px 10px 0;
                padding: 0 !important;
              }

              .last-in-row {
                margin: 0 0 10px 0;
              }

              .orders-panel-header, .account-profile-header {
                width: 100%;
                margin: 15px 0 10px 0;

                &.orders-panel-header {
                  margin: 5px 0 10px 0;
                }
              }

              .icon-link-btn .icon[data-v-943f621e] {
                display: none;
              }
            }
          }

          .custom-mobile-main {
            flex: 0 0 100%;
            max-width: 100%;
            padding-right: 0;
          }
        }
      }
    }

    @media (max-width: 750px) {
      .container {
        .top-row-container .row > div .cell-space {
          padding: 15px;
        }

        .content-wrapper .custom-mobile-aside {
          display: none;
        }
      }
    }
  }
</style>
