<template>
  <form class="search-bar-container"
        :class="{focused, faq, mobile}">
    <div v-click-outside="blur" class="wrapper d-flex align-items-center w-100 m-0">
      <fa class="sign fs-15 mr-3" icon="search" @click="focus"/>
      <input :value="searchPhrase"
             type="text"
             name="search_input"
             class="fs-15 d-block flex-grow-1"
             :placeholder="faq ? $t('searchHelp') : $t('searchInStore')"
             autocomplete="off"
             @keydown.enter.prevent.stop="search"
             @click="focus"
             @input="onSearchInput"
             ref="search_input"
             :aria-label="faq ? $t('searchHelp') : $t('searchInStore')"
      >
      <div class="button d-flex justify-content-center align-items-center h-100 overflow-hidden" @click="search">
        <span v-if="faq" class="fs-14">{{ $t('search') }}</Span>
        <fa v-else class="fs-18 m-0" icon="search"/>
      </div>
    </div>
  </form>
</template>

<script>
  export default {
    name: "SearchBar",
    props: [
      'faq',
      'mobile'
    ],

    data() {
      return {
        searchPhrase: '',
        focused: false
      }
    },

    computed: {
      storedProductsPhrase() {
        return this.$store.state.products_list.products.productPhrase;
      }
    },

    methods: {
      onSearchInput(event) {
          this.searchPhrase = event.target.value;
      },

      focus() {
        this.$refs.search_input.focus();
        !!this.faq ? '' : this.focused = true;
      },

      blur() {
        this.focused = false;
      },

      search() {
        this.$refs.search_input.focus();
        this.closeMobileMenu();

        if (this.faq) {
          this.findHelpCenterArticleByPhrase(this.searchPhrase);
          this.searchPhrase = '';

          if (this.$route.name !== 'help-center') {
            this.$router.push('/help-center');
          }

        } else {
          this.$store.commit('products_list/setProductPhrase', this.searchPhrase);

          if (this.$route.name !== 'search-results') {
            this.$store.commit('categories/setNullOnCurrentProductsCategoryCode');
            this.$router.push('/search-results/');

          } else {
            this.updateProductsList(this.searchPhrase);
          }
        }
      }
    },

    watch: {
      storedProductsPhrase(val) {

        if(!this.faq) {
          this.searchPhrase = val;
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .search-bar-container {
    display: flex;
    flex-grow: 1;
    transition: box-shadow $transitionStandardTime;

    .wrapper {
      justify-content: stretch;
      padding-left: 30px;

      .sign {
        color: $textColor14;
        transition: all $transitionStandardTime;
        cursor: pointer;
      }

      input {
        border: unset;
        height: 40px;
        color: $textColor2;
        cursor: pointer;
        outline: none !important;
        margin-right: 30px;

        &::placeholder {
          transition: color $transitionStandardTime;
          letter-spacing: 0.58px;
          color: $textColor2;
        }
      }

      .button {
        width: 0;
        opacity: 0;
        border-left: $line;
        transition: width $transitionStandardTime, opacity $transitionStandardTime;
        cursor: pointer;
        user-select: none;

        svg {
          transition: all $transitionStandardTime;
          color: $textColor1;
        }

        &:hover svg, &:hover span {
          color: $textColor6;
        }

        &:active svg {
          transform: scale(1.1);
        }

        span {
          color: $textColor2;
          letter-spacing: 0.5px;
          transition: color $transitionStandardTime;
          line-height: 1;
        }
      }
    }

    &.focused {
      box-shadow: 0 6px 10px rgba(41, 127, 202, 0.1);

      .wrapper {
        .sign {
          margin-right: 0 !important;
          width: 0;
          opacity: 0;
          pointer-events: none;
          cursor: unset;
        }

        .button {
          width: 80px;
          opacity: 1;
        }
      }
    }

    &.faq {
      width: 530px !important;
      height: 56px;
      box-shadow: $boxShadow8;
      border-radius: 2px;
      border: $border7;
      background-color: $textColor5;
      max-width: 100%;

      input {
        font-size: 14px;

        &::placeholder {
          font-size: 14px;
        }
      }

      .wrapper .button {
        width: 80px;
        opacity: 1;
        border-left: unset;
      }
    }

    &.mobile {
      background: white;
      border-radius: 4px;
      margin: 20px 0;
      width: 100%;
      flex-grow: unset !important;
      display: block;

      .wrapper {
        height: 40px !important;
        padding-left: 10px;
      }
    }

    @media (max-width: 868px) {
      .wrapper .button {
        margin-right: 1px;
        height: 40px !important;
      }
    }

    @media(max-width: 420px){
     &.faq{

       svg {
         display: none;
       }

       .wrapper{
         padding-left: 15px;

         input {
           margin-right: 0;
         }
       }
      }
    }

    @media (max-width: 330px) {
      .wrapper .button {
        width: 65px !important;
      }
    }
  }
</style>
