export const state = () => ({
  list: [],
  offerDetails: null,
  deliveryCoordinates: []
});

export const mutations = {
  updateMyOffersList(state, payload){
    state.list = payload[0].offers;
  },

  updateOfferDetails(state, payload){
    state.offerDetails = payload;
  },

  setDeliveryCoordinates(state, deliveryCoordinates) {
    state.deliveryCoordinates = deliveryCoordinates;
  }
};


