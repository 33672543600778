import Vue from 'vue'

Vue.mixin({
    data() {
        return {
            letterRegex: /^[-AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻżQqXx. ']+$/u,
            postalCodeRegex: /^\d{2}-\d{3}?$/u,
            notEmptyRegex: /^(?!\s*$).+/,
            phoneNumberRegex: /^(?:\(?\+?48)?(?:[-\.\(\)\s]*(\d)){9}\)?$/,
            nipRegex: /^[0-9]{10}$/,
            emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        }
    },
    methods: {
        fullFilledLetterValidator(value) {
            return this.letterRegex.test(value);
        },

        letterValidator(value) {
            return this.letterRegex.test(value) || value.length === 0;
        },

        postalCodeValidator(value) {
            return this.postalCodeRegex.test(value);
        },

        notEmptyValidator(value) {
            return this.notEmptyRegex.test(value);
        },

        phoneNumberValidator(value) {
            return this.phoneNumberRegex.test(value);
        },

        nipValidator(value) {
            return this.nipRegex.test(value);
        },

        nipValidatorWithValidEmptyInput(value) {
            return this.nipRegex.test(value) || value.length === 0;
        },

        optionalNipValidator(value) {
            return this.nipRegex.test(value) || value === '';
        },

        emailValidator(value) {
            return this.emailRegex.test(value);
        },

        passValidator(pass, repass) {
            return !!pass && !!repass && pass === repass
        },

        min4Validator(value) {
            return value.length > 3;
        },

        cityValidator(value) {
            return this.letterValidator(value) && this.notEmptyValidator(value);
        }
    }
});
