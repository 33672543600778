import Vue from 'vue'

Vue.mixin({
    computed: {
        perPageNews() {
            return this.$store.state.articles.navigation.perPage;
        },

        _currentArticlesCategoryCode() {
            return this.$store.state.article_categories.currentArticlesCategoryCode;
        },
    },
    methods: {
        updateArticleCategoriesList() {
            return this.$axios.$get('/shop-api/sections/')
                .then(response => {
                    this.$store.commit(
                        'article_categories/updateArticleCategoriesList',
                        {response}
                    );
                })
                .catch(err => this.devLog('updateArticleCategoriesList err', err));
        },

        selectArticleCategory(category = null, paramIsString = false) {
            let temp = null;

            if (category) {
                if (paramIsString) {
                    temp = category;

                } else if (!!category.link) {
                    temp = category.link;

                } else if (!!category.code) {
                    temp = category.code;
                }
            }

            if (temp) {
                window.history.replaceState({}, '', `/articles-list/${temp}`);

            } else {
                window.history.replaceState({}, '', `/articles-list`);
            }

            this.$store.commit(
                'article_categories/selectArticleCategory',
                temp
            );

            this.$store.commit('loading/show', this.$t('notify.loading'));

            if (this._currentArticlesCategoryCode && this._currentArticlesCategoryCode.length) {
                this.updateArticlesListByCode();

            } else {
                this.updateArticlesList();
            }
        },

        updateArticlesList(page = 1) {
            this.$axios.$get(
                '/shop-api/page',
                {
                    params: {
                        page,
                        limit: this.perPageNews
                    }
                })
                .then(response => {
                    this.$store.commit(
                        'articles/updateArticlesList',
                        {response}
                    );
                })
                .catch(err => this.devLog('updateArticlesList err', err))
                .then(() => this.$store.commit('loading/hide'))
        },

        updateArticlesWorkingList(page = 1) {
            return this.$axios.$get(
                '/shop-api/page',
                {
                    params: {
                        page,
                        limit: 200
                    }
                })
                .then(response => {
                    this.$store.commit(
                        'articles/updateArticlesWorkingList',
                        {response}
                    );
                })
                .catch(err => this.devLog('updateArticlesList err', err));
        },

        updateArticlesListByCode(page = 1) {
            this.$axios.$get(
                `/shop-api/page/by-section-code/${this._currentArticlesCategoryCode}`,
                {
                    params: {
                        page,
                        limit: this.perPageNews
                    }
                })
                .then(response => {
                    this.$store.commit(
                        'articles/updateArticlesList',
                        {response}
                    );
                })
                .catch(err => this.devLog('updateArticlesListByCode err', err))
                .then(() => this.$store.commit('loading/hide'))
        },

        updateInformationArticlesList(page = 1) {
            this.$axios.$get(
                '/shop-api/info-articles',
                {
                    params: {
                        page,
                        limit: this.perPageNews
                    }
                })
                .then(response => {
                    this.$store.commit(
                        'articles/updateArticlesList',
                        {response}
                    );
                })
                .catch(err => this.devLog('updateInformationArticlesList err', err));
        }
    }
});
