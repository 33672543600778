<template>
  <div class="btn-wrapper">
    <div class="basket-amount-btn" @click="goTo('/basket')">
      <div class="img-container">
        <img src="/webp/cart.webp" alt="ikona koszyka"/>
      </div>
      <span class="text" :class="{empty: !amount}">{{ amount }} <span class="mobile-hide"> {{ $t('summaryStep.pcs') }}</span></span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "BasketAmountBtn",
    computed: {
      amount() {
        return this.productsBasket.length;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .btn-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;

    .basket-amount-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;

      &:hover .text {
        color: $textColor6;
      }

      .img-container {
        width: 33px;
        height: 28px;
        overflow: hidden;
        position: relative;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-40%, -50%);
          height: 81px;
          width: 57px;
        }
      }

      .text {
        font-size: 1.3rem;
        font-weight: 700;
        letter-spacing: 0.5px;
        line-height: 1;
        transition: all $transitionStandardTime;
        max-height: 13px;
        opacity: 1;
        overflow: hidden;

        &.empty {
          max-height: 0;
          opacity: 0;
        }
      }
    }

    @media (max-width: 400px) {
      width: 35px;

      .basket-amount-btn {
        &:hover .text {
          color: $textColor5;
        }

        .text {
          background: $textColor6;
          color: $textColor5;
          font-size: 1.4rem;
          border-radius: 50%;
          max-height: unset;
          padding: 4px;
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(30%, -50%);

          .mobile-hide {
            display: none;
          }
        }
      }
    }
  }
</style>
