export const state = () => ({
  list: [],
  orderDetails: null
});

export const mutations = {
  updateMyOrdersList(state, payload){
    state.list = payload[0].orders;
  },
  updateOrderDetails(state, payload){
    // console.log('updateOrderDetails', payload);
    state.orderDetails = payload;
  }
};


