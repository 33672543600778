export const state = () => ({
    channel: null,
    currency: null,
    locale: null,
    localeIso: '',
    countryCodes: []
});

export const mutations = {
    setData(state, data) {
        state.channel = !!data.channel ? data.channel : null;
        state.currency = !!data.currency ? data.currency : null;
        state.locale = !!data.locale ? data.locale : '';
        state.countryCodes = !!data.countryCodes ? data.countryCodes : [];
        state.localeIso = !!data.locale ? data.locale : '';
    },
    setLocaleIso: (state, locale) => state.localeIso = locale
};
