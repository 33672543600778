<template>
  <div class="purchasing-process" :class="{preventScroll: isMobileModalOpen, 'wcag-contrast': contrastMode}">
    <loading/>
    <notifications/>
    <b-container>
      <layout-header-purchasing-process/>
      <nuxt/>
    </b-container>
    <layout-footer/>
    <layout-logo-imgs />
  </div>
</template>

<script type="text/javascript">
  import LayoutHeaderPurchasingProcess from '~/components/LayoutComponents/LayoutHeaderPurchasingProcess'
  import LayoutFooter from '~/components/LayoutComponents/LayoutFooter'
  import Loading from '~/components/UtilityComponents/Loading'
  import LayoutLogoImgs from '~/components/LayoutComponents/LayoutLogoImgs';

  export default {
    name: 'PurchasingProcess',
    data() {
      return {}
    },
    components: {
      LayoutFooter,
      LayoutHeaderPurchasingProcess,
      Loading,
      LayoutLogoImgs,
    }
  }
</script>

<style lang="scss">
  @media (max-width: 992px) {
    .purchasing-process {
      .container {
        max-width: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }
</style>
