<template>
  <transition name="fade">
    <div v-show="!!visible" class="loading-screen">
      <div class="wrap">
        <div>
          <b-spinner variant="light" style="width: 6rem; height: 6rem;"></b-spinner>
        </div>
        <div v-if="!!reason && reason.length" class="info mt-5">{{ reason }}</div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'Loading',
    computed: {
      reason() {
        return this.$store.state.loading.reason;
      },

      visible() {
        return this.$store.state.loading.visible;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .loading-screen {
    position: fixed;
    z-index: 30000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(#222, .6);

    .wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      width: 50vw;
      height: 30vh;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      min-width: 210px;

      .info {
        color: #fff;
        font-size: 3rem;
      }
    }
  }
</style>
