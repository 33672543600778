<template>
  <div :class="{preventScroll: isMobileModalOpen, 'wcag-contrast': contrastMode}" class="simple-layout">
    <loading/>
    <notifications/>
    <b-container>
      <layout-header-simple/>
      <nuxt/>
    </b-container>
    <layout-footer/>
    <layout-logo-imgs />
  </div>
</template>

<script type="text/javascript">
  import LayoutHeaderSimple from '~/components/LayoutComponents/LayoutHeaderSimple';
  import LayoutFooter from '~/components/LayoutComponents/LayoutFooter';
  import Loading from '~/components/UtilityComponents/Loading';
  import LayoutLogoImgs from '~/components/LayoutComponents/LayoutLogoImgs';

  export default {
    components: {
      LayoutFooter,
      LayoutHeaderSimple,
      Loading,
      LayoutLogoImgs,
    }
  }
</script>
