export const state = () => ({
  list: [],
  loading: false
});

export const mutations = {
  startLoading(state) {
    state.loading = true;
  },
  stopLoading(state) {
    state.loading = false;
  },
  updateList(state, payload) {
    state.list = [];

    payload.forEach(item => {
      state.list.push(item.favoriteItems[0]);
    });
  }
};
