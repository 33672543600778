<template>
  <div class="anonoymous-newsletter-container">
    <div class="label">
      <span>{{ $t('newsletter.regularBasis') }}</span>
      <span>{{ $t('newsletter.newsletter') }}</span>
    </div>
    <div class="input-wrapper">
      <underlined-input v-model="email"
                        :label="$t('newsletter.enterEmail')"
                        :validator="emailValidator"
                        :disabled="sending"
                        ref="emailInput"
                        name="newsletter-email-input"
      />
      <button @click="trySend">
        <div class="loading-mask" :class="{ sending }">
          <div class="spinner-border text-success" role="status">
            <span class="sr-only">{{ $t('newsletter.loading') }}</span>
          </div>
        </div>
        <span :class="{ sending }">{{ $t('newsletter.signUP') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
  import UnderlinedInput from '~/components/UtilityComponents/UnderlinedInput'

  export default {
    data() {
      return {
        email: '',
        sent: false,
        isValid: true,
        sending: false
      }
    },
    components: {
      UnderlinedInput
    },
    methods: {
      trySend() {
        if (this.validateInputs() && !this.sending) {
          this.sending = true;

          this.$axios.$post('/shop-api/newsletter/subscribe/' + this.email)
              .then(response => {
                this.sent = true;

                this.$notify({
                  title: this.$t('emailAddedToTheListOfSubscribers')
                });
              })
              .catch(err => {
                this.sending = false;

                this.$notify({
                  title: this.$t('failedToSubscribe'),
                  text: this.$t('tryAgain'),
                  type: 'warn'
                });
              })

        } else {
          this.$notify({title: this.$t('enterValidMail'), type: 'warn'});
        }
      },

      validateInputs() {
        Object.values(this.$refs).forEach(ref => ref.validate());
        return Object.values(this.$refs).find(x => x.valid_ === false) === undefined;
      }
    }
  }
</script>

<style lang="scss">
  .anonoymous-newsletter-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 15px;
    width: 480px;
    max-width: 100%;

    .label {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-right: 20px;

      span:first-child {
        color: $textColor5;
        font-size: 1.3rem;
        font-weight: 400;
        letter-spacing: 0.46px;
        margin-bottom: 4px;
      }

      span:last-child {
        color: $textColor5;
        font-size: 2rem;
        font-weight: 400;
        line-height: 1;
      }
    }

    .input-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-grow: 1;
      height: 43px;

      .underlined-input {
        height: 100% !important;
        line-height: 1 !important;
        flex-grow: 1;
        border-color: transparent;
        border-radius: 2px;
        background-color: $bgColor3;

        input {
          padding-left: 20px !important;
          color: $textColor5;
          font-size: 1.3rem;
          font-weight: 400;
          letter-spacing: 0.46px;
          height: 43px;

          &::placeholder {
            color: $textColor12;
          }
        }

        .ln {
          margin-top: -2px;
          width: calc(100% + 3px);
        }

        .right-slot {
          display: none;
        }
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $bgColor3;
        border-radius: 2px;
        background: $textColor1;
        height: 100%;
        width: 100px;
        font-size: 1.3rem;
        font-weight: 400;
        letter-spacing: 0.46px;
        transition: all .3s;
        position: relative;

        &:hover span {
          color: $textColor5;
        }

        &:active {
          background: $bgColor3;
        }

        span {
          color: $textColor6;
          transition: all .3s;

          &.sending {
            opacity: 0;
          }
        }

        .loading-mask {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25px;
          height: 25px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: opacity .3s;
          opacity: 0;

          &.sending {
            opacity: 1;
          }

          .spinner-border {
            width: 2rem !important;
            height: 2rem !important;
          }
        }
      }
    }

    @media (max-width: 500px) {
      flex-direction: column;
      padding-right: 0;

      .label {
        margin-bottom: 20px;
      }

      .input-wrapper {
        width: 100%;

        .underlined-input input {
          padding-left: 10px !important;
        }
      }
    }
  }
</style>
