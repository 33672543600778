import Vue from 'vue'

Vue.mixin({
    methods: {
        completeOrder(basketToken, payload, cb = () => {
        }) {
            this.$store.commit('loading/show', this.$t('notify.orderBeingSent'));

            this.$axios.$put('/shop-api/checkout/' + basketToken + '/instant-complete', payload)
                .then(response => {
                    // console.log('response', response);
                    cb(response.orderNumber);
                    this.$router.push('/confirmation');
                })
                .catch(err => {
                    // console.log('err', err);

                    if (err.response.status === 401) {

                        this.$notify({
                            type: 'error',
                            title: this.$t('notify.accountAssignedToEmail'),
                            text: this.$t('notify.toCompletePurchaseUsingTheEmail')
                        });

                        this.$router.push('/login');

                    } else {
                        this.$router.push('/purchase-submit-failed');
                    }
                })
                .then(() => {
                    this.$store.commit('loading/hide')
                })
        }
    }
});
