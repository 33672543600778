import Vue from 'vue'

Vue.mixin({
  methods: {
    sumOptionValue(option) {
      let temp = 0;

      if (!!option.suboptions) {

        let optionValue = option.suboptions.find(element => {
          return element.active
        });
        !!optionValue && !!optionValue.value ? temp += optionValue.value : '';

        if (!!optionValue && !!optionValue.options) {

          let attribute = optionValue.options.find(element => {
            return element.active
          });

          if (!!attribute && !!attribute.options) {

            let attributeValue = attribute.options.find(element => {
              return element.active
            });
            !!attributeValue && !!attributeValue.value ? temp += attributeValue.value : '';

            if (!!attributeValue && !!attributeValue.options) {

              let subattribute = attributeValue.options.find(element => {
                return element.active
              });

              if (!!subattribute && !!subattribute.options) {

                let subattributeValue = subattribute.options.find(element => {
                  return element.active
                });
                !!subattributeValue && !!subattributeValue.value ? temp += subattributeValue.value : '';
              }
            }
          }
        }
      }

      return temp;
    },
    sumConfiguratorOptionsValue(options) {
      let temp = 0;

      if (!!options) {
        options.forEach(option => {
          temp += this.sumOptionValue(option)
        });
      }

      return temp;
    }
  }
});
