export const state = () => ({
  reason: '',
  visible: false
});

export const mutations = {
  show (state, data) {
    state.visible = true;

    if(state.reason === null){
      state.reason = data;
    }
  },

  hide (state) {
    state.reason = null;
    state.visible = false;
  }
};
