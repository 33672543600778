import Vue from 'vue'

Vue.mixin({
    methods: {
        updateMyOrdersList() {
            if (this.isUserLogged) {
                this.$store.commit('loading/show', this.$t('notify.updatingData'));

                this.$axios.$get('/shop-api/me/order-history')
                    .then(response => this.$store.commit('orders/updateMyOrdersList', response))
                    .catch(err => this.devLog('updateMyOrdersList', err))
                    .then(() => this.$store.commit('loading/hide'));
            }
        },

        updateOrderDetails(tokenValue) {
            if (this.isUserLogged) {
                this.$store.commit('loading/show', this.$t('notify.loadingOrder'));

                this.$axios.$get('/shop-api/orders/' + tokenValue)
                    .then(response => this.$store.commit('orders/updateOrderDetails', response))
                    .catch(err => this.devLog('updateOrderDetails', err))
                    .then(() => this.$store.commit('loading/hide'));
            }
        },

        updateMyOffersList() {
            if (this.isUserLogged) {
                this.$store.commit('loading/show', this.$t('notify.refreshing'));

                this.$axios.$get('/shop-api/me/offers')
                    .then(response => this.$store.commit('offers/updateMyOffersList', response))
                    .catch(err => this.devLog('updateMyOffersList', err))
                    .then(() => this.$store.commit('loading/hide'));
            }
        },

        updateOfferDetails(tokenValue) {
            if (this.isUserLogged) {
                this.$store.commit('loading/show', this.$t('notify.loadingOffer'));

                this.$axios.$get('/shop-api/orders/' + tokenValue)
                    .then(response => this.$store.commit('offers/updateOfferDetails', response))
                    .catch(err => this.devLog('updateOfferDetails', err))
                    .then(() => this.$store.commit('loading/hide'));
            }
        },

        confirmOffer(tokenValue) {
            if (this.isUserLogged) {
                this.$store.commit('loading/show', this.$t('notify.updateData'));

                this.$axios.$post('/shop-api/me/offer/' + tokenValue + '/accept')
                    .then(response => {
                        this.updateMyOrdersList();
                        this.$router.push('/my/offers/');
                    })
                    .catch(err => this.devLog('confirmOffer', err))
                    .then(() => this.$store.commit('loading/hide'));
            }
        },

        editClientDataInOffer(tokenValue, payload) {
            this.$store.commit('loading/show', this.$t('notify.confirmChanges'));

            this.$axios.$put(
                '/shop-api/me/offer/' + tokenValue + '/address',
                payload
            )
                .then(response => this.updateOfferDetails(tokenValue))
                .catch(err => this.devLog('editClientDataInOffer', err))
                .then(() => this.$store.commit('loading/hide'));

        },

        updateComplaintsList() {
            this.$store.commit('loading/show', this.$t('notify.refreshingList'));

            this.$axios.$get('/shop-api/me/refund-requests')
                .then(response => this.$store.commit('complaints/updateComplaintsList', response))
                .catch(err => this.devLog('updateComplaintsList', err))
                .then(() => this.$store.commit('loading/hide'));
        },

        updateReasonsListByRefundType() {
            this.$axios.$get('/shop-api/applications-reasons/by-type/refund_type')
                .then(response => this.$store.commit('complaints/updateReasonsListByRefundType', response))
                .catch(err => this.devLog('updateReasonsListByRefundType', err));
        },

        updateReasonsListByReturnType() {
            this.$axios.$get('/shop-api/applications-reasons/by-type/return_type')
                .then(response => this.$store.commit('complaints/updateReasonsListByReturnType', response))
                .catch(err => this.devLog('updateReasonsListByReturnType', err));
        },

        sendComplaint(orderId, orderItemId, formData) {
            this.$store.commit('loading/show', this.$t('notify.sendingApplication'));

            this.$axios.$post('/shop-api/order/' + orderId + '/item/' + orderItemId + '/refund-request', formData)
                .then(response => this.$router.push('/my/complaints'))
                .catch(err => {
                    this.devLog('sendComplaint', err);
                    this.$notify({
                        title: this.$t('notify.errorOccured'),
                        text: this.$t('notify.tryAgain')
                    });
                })
                .then(() => this.$store.commit('loading/hide'));
        },

        updateComplaintDetails(id) {
            this.$store.commit('loading/show', this.$t('notify.refreshingApplication'));

            this.$axios.$get('/shop-api/me/refund-request/' + id)
                .then(response => this.$store.commit('complaints/updateComplaintDetails', response))
                .catch(err => this.devLog('addMessageToComplaint', err))
                .then(() => this.$store.commit('loading/hide'));
        },

        addMessageToComplaint(id, formData) {
            this.$store.commit('loading/show', this.$t('notify.sendingMessage'));

            this.$axios.$post('/shop-api/me/refund-request/' + id + '/send-message', formData)
                .then(response => {
                    this.$store.commit('loading/hide');
                    this.updateComplaintDetails(id);
                })
                .catch(err => {
                    this.$store.commit('loading/hide');
                    this.devLog('addMessageToComplaint', err);
                })
        },

        downloadRefundAttachment(hash, name) {
            const link = document.createElement('a');
            link.href = this.$axios.defaults.baseURL + '/shop-api/me/refund-request/message-file/' + hash;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link)
        },

        getAddresses(dontShowLoader = false) {
            if (!dontShowLoader) {
                this.$store.commit('loading/show', this.$t('notify.loading'));
            }

            this.$axios.$get('/shop-api/address-book/')
                .then(resp => this.$store.commit('user/updateAddresses', resp._embedded.items))
                .catch(err => this.devLog('my addresses getAddresses /shop-api/address-book/', err))
                .then(() => {
                    if (!dontShowLoader) {
                        this.$store.commit('loading/hide')
                    }
                });
        }
    }
});
