export const state = () => ({
    list: [],
    currentProductsCategoryCode: null,
    openedMenu: false,
});

export const mutations = {
    toggleMenu(state) {
        state.openedMenu = !state.openedMenu;
    },

    closeMenu(state) {
        state.openedMenu = false;
    },

    openMenu(state) {
        state.openedMenu = true;
    },

    selectProductCategory(state, code) {
        const manageSelectedParameter = (items, code) => {
            items.forEach(item => {
                item.selected = (item.code === code) || (item.link === code);

                if(item.selected){
                    state.currentProductsCategoryCode = item.code;
                }

                if (!!item.children && item.children.length) {
                    manageSelectedParameter(item.children, code);
                }
            });
        }

        manageSelectedParameter(state.list, code);
    },

    setNullOnCurrentProductsCategoryCode(state) {
        state.currentProductsCategoryCode = null;
    },

    cleanCategoryList(state) {
        state.list = [];
    },

    updateCategoryList(state, payload) {
        state.list = [];

        payload.response.forEach(cat => {
            cat.selected = false;

            if (cat.children.length) {
                cat.children.forEach(subcat => subcat.selected = false);
            }

            state.list.push(cat)
        });
    }
};
