<template>
  <div class="__nuxt-error-page">
    <div class="error">
        <h1>{{ statusCode }}</h1>
        <h2>{{ getErrorCodeInfo(statusCode) }}</h2>
        <nuxt-link to="/">{{ $t('goBackToHomePage') }}</nuxt-link>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'NuxtError',
    props: {
      error: {
        type: Object,
        default: null
      }
    },
    head () {
      return {
        title: this.getErrorCodeInfo(this.statusCode),
        meta: [
          {
            name: 'viewport',
            content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0'
          }
        ]
      }
    },
    computed: {
      statusCode () {
        return (this.error && this.error.statusCode) || 500
      },
      message () {
        return this.error.message || ''
      }
    },
    methods: {
      getErrorCodeInfo(errorCode) {
        switch (errorCode) {
          case 400:
            return 'Nieprawidłowe zapytanie';

          case 401:
            return 'Nieautoryzowany dostęp';

          case 405:
            return 'Niedozwolona metoda';

          case 404:
            return 'Strona nie została odnaleziona';

          case 500:
            return 'Serwer napotkał niespodziewane trudności';

          default:
            return 'Wystąpił błąd ogólny';
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .__nuxt-error-page {
    padding: 1rem;
    background: #F7F8FB;
    color: #47494E;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: sans-serif;
    font-weight: 100 !important;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    height: 100%;

    .error{
      max-width: 450px;

      h1 {
        line-height: 1;
        font-weight: 500;
        font-size: 5rem;
        text-align: center;
        margin-bottom: 10px;
        color: $textColor1;
      }

      h2 {
        color: $textColor2;
        margin-bottom: 20px;
        font-size: 2.8rem;
        font-weight: 400;
        text-align: center;
      }

      a {
        transition: opacity 0.3s;
        font-size: 2rem;
        color: $textColor6;
        text-align: center;
        font-weight: 400;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
</style>
