<template>
    <div class="underlined-input" :class="{bordered, disabled, topLabelOnMobile, fadedEnd}" :style="{lineHeight: lh}"
         @click="disabled ? $notify({title: $t('fieldCompletedAutomatically')}) : ''">
      <div v-if="icon" class="icon" :style="{marginLeft: (bordered ? 15 : 0) + 'px'}">
        <fa :icon="icon"/>
      </div>
      <div class="right-slot">

        <transition name="fade" mode="out-in">
          {{ loading }}
          <b-spinner v-if="$slots.right && loading" variant="success" class="mr-3"></b-spinner>
        </transition>

        <transition name="fade" mode="out-in">
          <slot v-if="$slots.right" name="right"></slot>
          <span v-else-if="showRightSlot" class="text-green custom-label" :class="{ fadedEnd }">
            {{ label }}
          </span>
        </transition>

        <span class="mobile-label">{{ label }}</span>
      </div>
      <input v-model="value_"
             ref="input"
             :placeholder="label || ''"
             :style="{paddingLeft, paddingRight}"
             :type="type"
             :disabled="disabled"
             :class="{ withIcon: icon }"
             @focus="focused=true"
             @blur="focused=false"
             @keyup="keyup"
      >
      <div :class="{
      ln: true,
      valid: focused || valid_ === true,
      invalid: valid_ === false
    }"></div>
      <div v-if="optional" class="optional">{{ $t('optional') }}</div>
    </div>
</template>

<script>
  export default {
    name: 'UnderlinedInput',
    props: [
      'autoFocus',
      'border',
      'disabled',
      'icon',
      'label',
      'lineHeight',
      'required',
      'rightLabel',
      'type',
      'valid',
      'validator',
      'value',
      'repass',
      'loading',
      'topLabelOnMobile',
      'paddingRight',
      'fadedEnd',
      'optional'
    ],
    data() {
      return {
        value_: '',
        focused: false,
        valid_: null,
      }
    },
    computed: {
      bordered() {
        return this.border !== false && this.border !== 'false'
      },
      lh() {
        return (this.lineHeight || 52) + 'px'
      },
      paddingLeft() {
        return (15 + (this.icon ? 31 : 0) + (this.bordered ? 15 : 0)) + 'px'
      },
      showRightSlot() {
        return this.rightLabel !== false && this.rightLabel !== 'false' && this.value
      },
      isPostalCodeValidator() {
        return !!this.validator && this.validator.name === 'bound postalCodeValidator';
      }
    },
    methods: {
      validate() {
        if (this.validator) {
          this.valid_ = typeof this.repass === 'string' ? this.validator(this.value_, this.repass) : this.validator(this.value_);

        } else {
          this.valid_ = null
        }

        return this.valid_;
      },

      keyup(ev) {
        if (this.isPostalCodeValidator) {
          this.value_ = this.value_.replace(/[^\d-]/g, '');

          if (this.value_.length === 3 && ev.key !== 'Backspace' && ev.key !== '-') {
            this.value_ = this.value_.slice(0, 2) + "-" + this.value_.slice(2);
          }

          if (this.value.length >= 6) {
            this.value_ = this.value_.substr(0, 6);
          }
        }

        if (ev.which == 13) {
          this.$emit('enter', this.value_);
        }
      }
    },
    watch: {
      value() {
        this.value_ = this.value;
      },
      valid() {
        this.valid_ = this.valid
      },
      value_() {
        this.$emit('input', this.value_);
        this.validate();
      }
    },
    created() {
      this.value_ = this.value || '';
    },
    mounted() {
      if (this.autoFocus) {
        this.$nextTick(() => {
          if (!!this.$refs.input) {
            this.$refs.input.focus();
          }
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .underlined-input {
    position: relative;

    .optional {
      position: absolute;
      font-size: 1.2rem;
      top: -8px;
      left: 30px;
      color: #00ad52;
      height: 15px;
      line-height: 13px;
      padding: 0 5px;
      margin: 0;
      background: white;
    }

    &.bordered {
      border: solid 1px #c9d6db;
      border-bottom: 0;
      border-radius: 2px;
    }

    &.disabled {
      background: $bgColor1;
    }

    input {
      background: transparent;
      border: solid 0;
      width: 100%;
      outline: none;
    }

    .ln {
      background: #c9d6db;
      height: 1px;
    }

    .green-gradient {
      background: #00d788;
      background: linear-gradient(to right, #00d788 0%, #008f70 100%);
    }

    .red-gradient {
      background: #d70088;
      background: linear-gradient(to right, #d70088 0%, #8f0070 100%);
    }

    .valid {
      @extend .ln;
      @extend .green-gradient;
    }

    .invalid {
      @extend .ln;
      @extend .red-gradient;
    }

    .icon {
      height: 0;
      text-align: left;
      font-size: 1.8rem;
    }

    .right-slot {
      height: 0;
      position: relative;
      top: 1px;
      left: 0;
      right: 0;
      text-align: right;
      padding-right: 15px;

      .mobile-label {
        display: none;
      }

      .custom-label{
        &.fadedEnd{
          position: relative;

          &:before {
            content: '';
            width: 40px;
            height: calc(100% - 1px);
            background: $gradient10;
            position: absolute;
            top: 0;
            right: 100%;
            z-index: 1;
          }
        }
      }
    }

    @media (max-width: 600px) {
      .optional {
        left: 10px;
      }

      input {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 15px !important;

        &.withIcon {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          padding-left: 30px !important;
        }
      }
    }

    @media (max-width: 500px) {
      &.fadedEnd{
        position: relative;

        &:before {
          content: '';
          width: 40px;
          height: calc(100% - 1px);
          background: $gradient10;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
        }
      }

      input {
        padding-right: 0 !important;
      }

      &.topLabelOnMobile {
        input::placeholder {
          opacity: 0;
        }

        .custom-label {
          display: none;
        }

        .mobile-label {
          display: block;
          position: absolute;
          top: -30px;
          left: 0;
          line-height: 1;
          font-weight: 400;
        }
      }
    }
  }
</style>
