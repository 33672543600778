export const state = () => ({
    list: [],
    loading: false,
    navigation: {
        perPage: 10,
        currentPage: 1,
        pagesCount: null
    },
    workingList: []
});

export const mutations = {
    updateArticlesList(state, payload) {
        state.navigation.perPage = payload.response.limit;
        state.navigation.currentPage = payload.response.page;
        state.navigation.pagesCount = payload.response.pages;
        state.list = payload.response._embedded.items;
    },

    updateArticlesWorkingList(state, payload) {
        state.workingList = payload.response._embedded.items;
    }
};

