export const state = () => ({
  locales: [
    {
      code: 'pl',
      name: 'Polski'
    }
  ],
  locale: 'pl'
});

export const mutations = {
  SET_LANG(state, locale) {
    if (state.locales.find(el => el.code === locale)) {
      state.locale = locale
    }
  }
};
