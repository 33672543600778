<template>
  <nav :class="{'light-mode': menu}" aria-label="WCAG Accessability Menu"
       class="wcag-navigation container">
    <div v-if="isUserLogged && name && name.length" class="welcome-text">
      {{ $t('welcome') }}, {{ name }}!
    </div>
    <div class="container">
      <span class="icon" title="Normal Mode" @click="changeContrastMode(false)">
        <fa icon="adjust" class="fs-16 color-def"/>
      </span>
      <span class="icon" title="Contrast Mode" @click="changeContrastMode(true)">
        <fa icon="adjust" class="fs-16 color-accent"/>
      </span>
      <span class="icon" title="Normal Font" @click="changeFontSizeMode(0)">
        <fa icon="font" class="fs-14 color-def"/>
      </span>
      <span class="icon" title="Bigger Font" @click="changeFontSizeMode(1)">
        <fa icon="font" class="fs-16 color-def"/>
      </span>
      <span class="icon mr-3" title="Biggest Font" @click="changeFontSizeMode(2)">
        <fa icon="font" class="fs-18 color-def"/>
      </span>
      <nuxt-link class="logout-button" :class="{ isUserLogged }" to="/" @click.native="logout">
        <span class="off-icon">
          <fa icon="power-off"/>
        </span>
        <span class="text">{{ $t('orderPanel.logout') }}</span>
      </nuxt-link>
    </div>
  </nav>
</template>
<script>
  export default {
    name: 'WcagHeaderNavigation',
    props: ['simple', 'menu'],
    computed: {
      name() {
        return this.isUserLogged ? this.$store.state.user.clientData.firstName : ''
      }
    },
    methods: {
      setContrastFromLocalStorage() {
        let contrastMode = localStorage.getItem('contrast_mode');
        if (contrastMode) {
        }
      },

      setFontSizeModeFromLocalStorage() {
        let fontSizeMode = localStorage.getItem('font_size');
        if (fontSizeMode) {
        }
      },

      changeContrastMode(mode) {
        this.$store.commit('wcag/setContrastMode', {contrastMode: mode});
        if (process.browser) {
          localStorage.setItem('contrast_mode', mode);
        }
      },

      changeFontSizeMode(mode) {
        this.$store.commit('wcag/setFontSizeMode', {fontSizeMode: mode});
        if (process.browser) {
          localStorage.setItem('font_size', mode);
        }
        window.document.body.parentElement.classList = [];
        if (mode > 0) {
          window.document.body.parentElement.classList.add('wcag-body-font-' + mode);
        }
      },
      logout() {
        this.$store.commit('user/logout');
        this.$store.commit('basket_token/cleanBasketTokenAfterLogout');
        this.$store.commit('basket/cleanBasketAfterLogout');
        this.$store.commit('delivery/cleanData');
        this.$store.commit('payment/cleanData');
        this.getNewBasketAsQuest();
        this.$notify({
          title: this.$t('notify.loggedOut'),
          text: this.$t('notify.sessionEnded')
        })
        this.$router.push('/login');
      }
    },
    created() {
      if (process.browser) {
        this.setFontSizeModeFromLocalStorage();
        this.setContrastFromLocalStorage();
      }
    }
  }
</script>
<style lang="scss" scoped>
  .wcag-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    flex-direction: row;
    max-width: 1140px;
    height: 41px !important;
    max-height: 41px !important;
    padding: 1rem 3rem !important;

    .welcome-text {
      white-space: nowrap;
      font-size: 1.4rem;
      margin-left: 5px;
      color: $textColor;
    }

    .container {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .icon {
        cursor: pointer;
        transition: $transitionStandardTime;
        padding: 0 5px;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    .logout-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 1.4rem;
      border-left: $line;
      transition: all .3s;
      overflow: hidden;
      opacity: 0;
      width: 0;

      &.isUserLogged {
        width: 112px;
        opacity: 1;
      }

      .off-icon {
        color: #ff537f;
        padding: 0 5px;
        transition: all .3s;
        margin: 0 .5rem 0 1rem;
      }

      .text {
        white-space: nowrap;
        transition: all .3s;
      }

      &:hover {
        .text {
          color: $textColor6;
        }
      }
    }

    &.light-mode {
      display: none !important;
      padding: 0;
      margin: 0;

      @media (max-width: 750px) {
        display: flex !important;
      }

      .container {
        padding: 0;

        .color-def {
          color: #FFF;
        }
      }
    }

    @media (max-width: 1199px) {
      max-width: 960px;
    }

    @media (max-width: 992px) {
      padding: 0 1rem !important;
    }

    @media (max-width: 750px) {
      .container {
        margin-left: 20px;
      }

      .welcome-text {
        color: white;
        margin-left: 0;
      }

      .logout-button {
        .text {
          color: white;
        }

        &:hover {
          .off-icon {
            opacity: 0.5;
          }
        }
      }
    }

    @media (max-width: 500px) {
      &.light-mode{
        flex-direction: column;
        height: 60px !important;
        max-height: 60px !important;
        padding: 0;

        .container {
          justify-content: center !important;
          margin-left: auto;
        }

        .welcome-text {
          margin-bottom: 10px;
        }

        .container {
          width: 100%;
        }
      }
    }
  }

  header {
    .links-container a {
      &:hover svg {
        color: $textColor6;
      }

      svg {
        color: $textColor2;
        transition: color $transitionStandardTime;
        width: 13px;
        margin-left: 3px;
      }
    }

    @media(max-width: 750px) {
      height: 50px;
    }
  }
</style>
