import pl from './pl.json';
// const locale = 'pl_PL';

export default ({ $axios }) => {
  return new Promise((resolve) => {
      resolve(pl);

      //pobieranie słownika z API
      // $axios.$get('/shop-api/dictionary?locale=' + locale)
      // .then((response) => {
      //   resolve(response);
      // })
      // .catch(() => {
      //   resolve(pl);
      // })
  });
}
