<template>
  <header class="configuration-process-header">
    <div class="column logo-wrapper">
      <nav class="d-flex align-items-center justify-content-start">
        <nuxt-link to="/" class="logo-btn">
          <img src="/webp/logo.webp" alt="domkidrewniane.org" width="145" height="58">
        </nuxt-link>
        <!--<lang-options/>-->
      </nav>
    </div>
    <div class="column">
      <h1 class="fs-24 color-def font-weight-normal">{{ $t('confTitle') }}</h1>
      <div class="cancel-btn h-100 d-flex justify-content-center align-items-center"
           @click="$router.push(getProductHref($store.state.configurator.product))"
      >
        <fa icon="times" class="fs-24"/>
      </div>
    </div>
  </header>
</template>

<script>
  import LangOptions from '~/components/UtilityComponents/LangOptions';

  export default {
    name: 'LayoutHeaderConfigurator',
    components: {
      LangOptions
    }
  }
</script>

<style lang="scss" scoped>
  $asideWidth: 350px;
  header.configuration-process-header {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
    max-width: 1140px;
    border-bottom: $border1;

    > .column:first-child {
      width: $asideWidth;
      flex-shrink: 0;
      border-right: $border1;

      nav {
        height: $rowHeight;
        padding: 0 15px;

        .logo-btn {
          display: inline-block;
        }
      }
    }

    > .column:last-child {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: calc(100% - #{$asideWidth});
      padding: 0 0 0 70px;

      h1 {
        margin: 0;
        flex-grow: 1;
      }

      .cancel-btn {
        border-left: $line;
        width: 88px;
        cursor: pointer;

        svg {
          transition: color $transitionStandardTime;
        }

        &:hover svg {
          color: $textColor6;
        }
      }
    }

    @media (max-width: 1101px) {
      > .column:last-child {
        padding: 0 0 0 30px;
      }
    }

    @media (max-width: 986px) {
      > .column:first-child {
        width: 175px;
      }

      > .column:last-child {
        width: calc(100% - 175px);
      }
    }

    @media (max-width: 750px){
      background: white;
      position: fixed;
      box-shadow: 0 6px 10px rgba(41, 127, 202, 0.1);
      left: 0;
      top: 0;
      z-index: 1001;
      height: 80px !important;
    }

    @media (max-width: 620px) {
      padding: 0;
    }

    @media (max-width: 475px) {
      > .column:first-child {
        width: unset;
        flex-grow: 1;
        border: unset;
      }

      > .column:last-child {
        width: unset;
        padding: 0;

        .cancel-btn {
          border-left: unset;
          width: 40px;
          cursor: pointer;
        }

        h1 {
          font-size: 1.6rem;
        }
      }
    }
  }
</style>
