import Vue from 'vue'

Vue.mixin({

  methods: {
    updateHelpCenterSections() {
      this.$axios.$get('/shop-api/faq-sections')
          .then(response => {
            this.$store.commit('help_center/updateHelpCenterSections', response);
            response.forEach(section => this.showHelpCenterBySectionCode(section.code));
          })
          .catch(err => {
            console.log('err', err);
          });
    },

    showHelpCenterBySectionCode(section_code) {
      this.$axios.$get('/shop-api/faq/by-section-code/' + section_code, {
        params: {
          page: 1,
          limit: 100
        }
      })
        .then(response => {
          this.$store.commit('help_center/assignArticlesToSections', {
            section_code: section_code,
            articles: response._embedded.items
          });
        })
        .catch(err => {
          console.log('err', err);
        });
    },

    findHelpCenterArticleByPhrase(phrase) {
      this.$axios.$get('/shop-api/faq/search/' + phrase, {
        params: {
          page: 1,
          limit: 100
        }
      })
        .then(response => {
          this.$store.commit('help_center/updateSearchResults', {
            phrase: phrase,
            articles: response._embedded.items
          });
        })
        .catch(err => {
          console.log('err', err);
        });
    }
  }
});
