<template>
  <div>
    <header class="fs-14 purchasing-process-header">
      <WcagHeaderNavigation/>
      <div class="mobile-container bottom-line">
        <div class="logo-container h-row">
          <nuxt-link to="/" class="logo-btn" title="Domkidrewniane.org">
            <img src="/webp/logo.webp" alt="domkidrewniane.org" width="145" height="58">
          </nuxt-link>
          <!--<lang-options/>-->
        </div>
        <div class="main-header-wrapper">
          <div class="pr-4 h-row">
            <nav aria-label="Categories Menu">
              <hamburger-btn/>
            </nav>
          </div>
        </div>
      </div>
      <div class="bottom-line mb-5">
        <b-row>
          <b-col lg="3" class="logo-wrapper">
            <nav class="d-flex align-items-center justify-content-start">
              <nuxt-link to="/" class="logo-btn">
                <img src="/webp/logo.webp" alt="domkidrewniane.org" width="145" height="58">
              </nuxt-link>
              <!--<lang-options/>-->
            </nav>
          </b-col>
          <b-col sm="9" class="main-header-wrapper">
            <div class="stages-container">
              <div class="stage"
                   v-for="stage in purchasingProcessStages" :key="stage.key"
                   :ref="stage.key < activeStage.key ? 'done_stage' : ''"
                   :class="getStageClasses(stage.key)"
              >
                <nuxt-link :to="stage.key < activeStage.key && activeStage.key !== 6 ? stage.url : ''"
                           :ref="stage.key === activeStage.key ? 'active_stage' : ''">
                  <span class="number">{{ '0' + stage.key }}</span>
                  <span class="title">{{ $t(stage.description) }}</span>
                  <fa class="mobile-icon" :icon="stage.icon"/>
                </nuxt-link>

                <div class="separator-icon">
                  <transition name="fade" mode="out-in">
                    <fa key="1" v-if="stage.key < activeStage.key" icon="check"
                        class="icon-check"
                    />
                    <fa key="2" v-else-if="stage.key >= activeStage.key && stage.key < purchasingProcessStages.length"
                        icon="angle-right"
                        class="icon-flysign"
                    />
                  </transition>
                </div>
                <div class="mobile-separator-icon" v-if="stage.key < purchasingProcessStages.length">
                  <fa icon="angle-right"/>
                </div>
              </div>
              <div class="line" :style="{width: line_width+'px'}"></div>
            </div>
          </b-col>
        </b-row>
      </div>
    </header>
    <main-nav :hidden-on-desktop="true"/>
  </div>
</template>

<script>
  import WcagHeaderNavigation from "~/components/LayoutComponents/WcagHeaderNavigation";
  import HamburgerBtn from '~/components/UtilityComponents/HamburgerBtn';
  import MainNav from '~/components/Categories/MainNav';
  import LangOptions from '~/components/UtilityComponents/LangOptions';

  export default {
    name: 'LayoutHeaderPurchasingProcess',
    components: {
      WcagHeaderNavigation,
      HamburgerBtn,
      MainNav,
      LangOptions,
    },
    data() {
      return {
        line_width: 10,
        activeStage: Object,
        purchasingProcessStages: [
          {
            url: '/basket',
            description: 'basket.basket',
            key: 1,
            icon: 'shopping-cart'
          },
          {
            url: this.isUserLogged ? '/login' : '/my',
            description: 'myMenu.login',
            key: 2,
            icon: 'sign-in-alt'
          },
          {
            url: '/delivery',
            description: 'myMenu.delivery',
            key: 3,
            icon: 'truck'
          },
          {
            url: '/payment',
            description: 'payment.payment',
            key: 4,
            icon: 'credit-card'
          },
          {
            url: '/summary',
            description: 'summary',
            key: 5,
            icon: 'handshake'
          },
          {
            url: '/confirmation',
            description: 'confirmation',
            key: 6,
            icon: 'check-circle'
          }
        ]
      }
    },
    methods: {
      updateLineWidth() {
        this.line_width = this.$refs.done_stage.reduce((t, {offsetWidth}) => t + offsetWidth, 0) + this.$refs.active_stage[0].$el.offsetWidth;
      },
      setActiveStage() {
        this.activeStage = this.purchasingProcessStages.find(x => x.url === this.$route.path);
      },
      getStageClasses(key) {
        return {
          active: key === this.activeStage.key,
          done: key < this.activeStage.key,
          undone: key > this.activeStage.key,
          f_grow_1: key < this.purchasingProcessStages.length
        }
      },
      isActualRoutePartOfPurchasingProcess(route) {
        return !!this.purchasingProcessStages.find((element) => {
          return element.url === route.path && element.url !== '/basket' && element.url !== '/login' && element.url !== '/my';
        })
      }
    },
    watch: {
      $route(route) {
        if (this.isActualRoutePartOfPurchasingProcess(route)) {
          this.setActiveStage();
          setTimeout(this.updateLineWidth, 100);
        }
      }
    },
    created() {
      this.setActiveStage();
    },
    mounted() {
      this.updateLineWidth();
      window.addEventListener('resize', this.updateLineWidth);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateLineWidth);
    }
  }
</script>

<style lang="scss" scoped>
  header.purchasing-process-header {
    .mobile-container{
      display: none;
    }

    nav {
      height: $rowHeight;
      padding: 0 15px;

      .logo-btn {
        display: inline-block;
      }
    }

    .main-header-wrapper {
      display: flex;
      align-items: center;

      .stages-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;

        .stage {
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &.done {
            color: $textColor6;

            .mobile-icon {
              color: $textColor6;
            }
          }

          &.active {
            a {
              font-weight: 500;
              cursor: default;

              &:hover {
                color: $textColor1;
              }
            }
          }

          &.undone {
            a {
              cursor: default;

              &:hover {
                color: $textColor1;
              }
            }

            .mobile-icon {
              color: $textColor3;
            }
          }

          &.f_grow_1 {
            flex-grow: 1;
          }

          a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px;
            height: 100%;
            color: $textColor1;
            font-size: 1.5rem;

            .number {
              font-weight: 300;
              margin-right: 5px;
            }

            .mobile-icon {
              display: none;
            }
          }

          .separator-icon, .mobile-separator-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            position: relative;

            &.mobile-separator-icon {
              display: none;

              svg {
                color: $textColor1;
              }
            }

            svg {
              position: absolute;
              font-size: 1.5rem;
            }

            .icon-check {
              color: $textColor6;
            }

            .icon-flysign {
              color: $textColor2;
            }
          }
        }

        .line {
          position: absolute;
          bottom: 0;
          left: 0;
          border-bottom: 2px solid $textColor6;
          transition: width $transitionStandardTime;
        }
      }
    }

    @media (max-width: 992px) {
      .row {
        margin: 0 !important;
      }

      .logo-wrapper {
        padding: 0 !important;
        flex: unset !important;
        max-width: unset !important;
        width: 174px !important;
      }

      .main-header-wrapper {
        flex-grow: 1;
        padding: 0 5px !important;
        max-width: unset;
      }
    }

    @media (max-width: 885px) {
      .row {
        flex-direction: column !important;

        .main-header-wrapper {
          width: 100% !important;
          border-top: 1px solid $borderColor;
        }
      }
    }

    @media(max-width: 750px) {
      height: 42px !important;
      margin-bottom: 75px;

      > .wcag-navigation{
        display: none;
      }

      .mobile-container{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 100% !important;
        background: white;
        position: fixed;
        box-shadow: 0 6px 10px rgba(41, 127, 202, 0.1);
        left: 0;
        top: 0;
        z-index: 1001;
        width: 100%;
        height: 80px;

        > div > div {
          padding-right: 0 !important;
        }

        .logo-container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: calc(25% - 22px);
          padding-left: 15px;

          .logo-btn {
            display: inline-block;
          }
        }

        .main-header-wrapper {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-wrap: wrap;
          flex-grow: 1;

          nav {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            padding: 0;
          }
        }
      }

      .row .main-header-wrapper {
        border-top: unset;
      }

      .logo-wrapper {
        display: none;
      }

      .stages-container {
        padding-top: 25px;

        .stage a .number {
          display: none;
        }
      }
    }

    @media(max-width: 650px) {
      .stages-container .stage a .title {
        font-size: 1.3rem;
      }
    }

    @media(max-width: 580px) {
      .main-header-wrapper .stages-container .stage .separator-icon svg {
        font-size: 1.1rem;
      }
    }

    @media(max-width: 520px) {
      .main-header-wrapper .stages-container .stage {
        a {
          .title {
            display: none;
          }

          .mobile-icon {
            display: block;
          }
        }

        .separator-icon {
          display: none;
        }

        .mobile-separator-icon {
          display: flex !important;
        }
      }
    }
  }
</style>
