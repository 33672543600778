<template>
  <div>
    <header class="fs-14">
      <WcagHeaderNavigation/>
      <div class="container bottom-line">
        <div class="logo-container h-row right-line">
          <nuxt-link to="/" class="logo-btn" title="Domkidrewniane.org">
            <img src="/webp/logo.webp" alt="domkidrewniane.org" width="145" height="58">
          </nuxt-link>
          <!--<lang-options/>-->
        </div>
        <div class="main-header-wrapper">
          <search-bar class="desktop-visible"/>
          <div class="pr-4 h-row">
            <nav aria-label="Categories Menu">
              <hamburger-btn/>
              <basket-amount-btn/>
              <!--<nuxt-link to="#" class="menu-btn right-line compare-btn"-->
              <!--:class="comparisionListLength > 1 ? 'menu-icon-bg-up' : null"-->
              <!--@click.native.prevent="comparisionNotSupported">-->
              <!--<div v-if="comparisionListLength">{{ comparisionListLength }}</div>-->
              <!--<span v-if="comparisionListLength > 1">Porównaj</span>-->
              <!--</nuxt-link>-->
              <div class="right-line fs-13 h-100 links-container">
                <div class="menu-line bottom-line">
                  <nuxt-link v-if="isUserLogged" to="/my" class="lh-1">
                    {{ $t('layoutHeader.myAccount') }}
                    <fa icon="user" size="sm" transform="up-1"/>
                  </nuxt-link>
                  <nuxt-link v-else to="/login" class="lh-1">
                    {{ $t('layoutHeader.login') }}
                    <fa icon="user" size="sm" transform="up-1"/>
                  </nuxt-link>
                </div>
                <div class="menu-line">
                  <nuxt-link to="/favourite-products" class="lh-1">
                    {{ $t('layoutHeader.favorite') }}
                    <fa icon="heart" size="sm" transform="up-1"/>
                  </nuxt-link>
                </div>
              </div>
              <span @click="goTo('/article/ue')" class="menu-btn ue-btn cursor-pointer"></span>
            </nav>
          </div>
        </div>
      </div>
      <search-bar class="tablet-visible"/>
    </header>
    <main-nav/>
  </div>
</template>

<script>
  import SearchBar from '~/components/UtilityComponents/SearchBar';
  import HamburgerBtn from '~/components/UtilityComponents/HamburgerBtn';
  import BasketAmountBtn from '~/components/UtilityComponents/BasketAmountBtn';
  import MainNav from '~/components/Categories/MainNav';
  import WcagHeaderNavigation from "~/components/LayoutComponents/WcagHeaderNavigation";
  import LangOptions from '~/components/UtilityComponents/LangOptions';

  export default {
    name: 'LayoutHeader',
    data() {
      return {
        selected: 0,
        prods: 0,
      }
    },
    components: {
      WcagHeaderNavigation,
      LangOptions,
      MainNav,
      SearchBar,
      HamburgerBtn,
      BasketAmountBtn
    },
    computed: {
      basketToken() {
        return this.$store.state.basket_token.token;
      },
      comparisionListLength() {
        return (this.$store.state.comparison_products.list || []).length;
      }
    },
    methods: {
      comparisionNotSupported() {
        if (this.comparisionListLength > 1) {
          this.$notify({title: this.$t('inPreparation'), text: this.$t('comparisonEngineNotWorkingYet')})
        } else {
          this.$notify({
            title: this.$t('productComparison'),
            text: this.$t('atLeastTwoProducts')
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .updatebtn {
    display: inline-block;
    padding: 10px;
    text-align: center;
    transition: all $transitionStandardTime;
    cursor: pointer;
    border: 1px solid $textColor6;

    &:hover {
      background: $textColor6;
      color: white;
    }
  }

  header {
    .container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .cursor-pointer {
      cursor: pointer;
    }

    .h-row {
      height: $rowHeight;
    }

    .px-custom {
      padding-left: 15px;
      padding-right: 15px;
    }

    .logo-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: calc(25% - 22px);
      padding-left: 15px;
      border-right: solid 1px #e4f3fa;
      position: relative;

      .logo-btn {
        display: inline-block;
      }
    }

    .main-header-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      flex-grow: 1;

      nav {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
      }
    }

    .links-container a {
      &:hover svg {
        color: $textColor6;
      }

      svg {
        color: $textColor2;
        transition: color $transitionStandardTime;
        width: 13px;
        margin-left: 3px;
      }
    }

    .menu-btn {
      height: $rowHeight;
      width: 80px;
      text-align: center;
      font-weight: bold;
    }

    .compare-btn {
      background: url("/webp/compare.webp") no-repeat center center;

      & > div {
        padding: 31px 0 2px;
      }
    }

    .ue-btn {
      background: url("/webp/ue.webp") no-repeat center center;
      margin: 0 25px;
    }

    .menu-icon-bg-up {
      background-position: center 16px;

      & > div {
        padding: 22px 0 2px;
      }
    }

    .menu-line {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
      height: 50%;
      padding: 0 20px;
    }

    .tablet-visible {
      display: none !important;
    }

    @media (max-width: 992px) {
      .container {
        max-width: 100% !important;

        > div > div {
          padding-right: 0 !important;
        }
      }
    }

    @media (max-width: 868px) {
      .logo-container {
        border-right: unset !important;
      }

      .main-header-wrapper {
        justify-content: flex-end !important;
      }

      .desktop-visible {
        display: none !important;
      }

      .tablet-visible {
        display: flex !important;
        border-bottom: $line;

        .wrapper {
          background: red;

          .button {
            margin-right: 16px !important;
            height: 40px !important;
          }
        }
      }
    }

    @media(max-width: 812px) {
      .logo-container {
        width: 174px !important;
      }
    }

    @media(max-width: 750px) {
      height: 50px;

      .links-container, .tablet-visible {
        display: none !important;
      }

      > .container {
        background: white;
        position: fixed;
        box-shadow: 0 6px 10px rgba(41, 127, 202, 0.1);
        left: 0;
        top: 0;
        z-index: 1001;

        .ue-btn {
          display: none;
        }
      }
    }

    @media (max-width: 525px) {
      .container {
        padding: 0 !important;
      }
    }

    @media (max-width: 330px) {
      .logo-container {
        padding-left: 0 !important;
        justify-content: center !important;
      }
    }
  }
</style>
